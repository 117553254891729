import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getService } from "../../services/services";
import * as services from "../../services/services.json";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import {
  Table,
  // TableBody,
  TableCell ,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  // IconButton,
  // Box,
  // Badge,
  // ButtonGroup,
  // InputBase,
  // Divider,
  // Tabs,
  // Tab,
  AppBar,
  // InputAdornment,
  // Toolbar,
  // Switch,
  Tooltip,
  // TextField,
  Fab,
  // Card,
  // CardHeader,
  // CardMedia,
  Typography,
  // CardContent,
  // CardActions,
  // Checkbox,
  // Grid,
  // Link,
  // Menu,
  // MenuItem
  Popper,
} from "@material-ui/core";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQrcode,faClosedCaptioning,faCopyright,faBan} from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@material-ui/icons/Add';
import DialogAddSemanticSearch from '../Dialog/DialogAddSemanticSearch';
import DialogDeleteSemantic from '../Dialog/DialogDeleteSemantic.jsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class SemanticSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog:false,
      showDialogDelete:false,
      orderStatus:"desc",
      orderSource:"asc",
      orderName:"desc",
      orderDate:"asc",
      items: [],
      itemDelete:"",
      anchorEl: null,
      itemInfo:""

    }

  }

  componentDidMount() {
    this.getAllSemanticSearch();

  }

  handleClickPopper = (event,item) => {
    this.setState({anchorEl: this.state.anchorEl ? null : event.currentTarget,
      itemInfo:this.state.anchorEl ? "" : item});
    };

  handleClickAway = () => {
    // if(this.state.anchorEl!=null)
      this.setState({anchorEl: null})
};

    getAllSemanticSearch = () => {
      // console.log("getAllSemanticSearch");
      let self=this;
      let serviceurl = process.env.REACT_APP_DOMAIN + services.semanticsearch_GET_ALL;

      serviceurl = serviceurl.replace("{APPNAME}", this.props.appstate.App.name);
      serviceurl = serviceurl.replace("{DOMAIN}", this.props.appstate.App.user.domain);
      serviceurl = serviceurl.replace("{OWNER}", this.props.appstate.App.user.userid);
      // console.log(serviceurl);
      getService(serviceurl, function (err, result) {
        if (err) {

          return;
        }
        // console.log(result);
        if (result.status === 200) {
          if(result.response.length>0){
            // console.log("setstate items");
            self.setState({items:result.response});
          }
          else self.setState({items:[]});
          return;
        }

        return;
      });
    }



    addSemantic= () => {
      this.setState({showAddDialog:true});
    }

    closeAddSemantic = () => {
      this.setState({showAddDialog:false});
    }

    AllSemantic = (item) => {

      this.props.history.push('/AllSemantic?id='+item._id);
    }

    //barra filtri function
    order=(event) =>{
      // console.log(event);
      switch (event.currentTarget.value) {
        case "source": // DA MODIFICARE PER SOURCE ANZICHE' SHORTNAME
        if (this.state.orderSource ==="asc"){
          let orderArray= this.itemsSortInverse("source");
          this.setState({items:orderArray,orderSource:"desc"});
        }else{
          let orderArray=this.itemsSort("source");
          this.setState({items:orderArray,orderSource:"asc"});
        }
        break;
        // case "name":
        // if (this.state.orderShortName ==="asc"){
        //   let orderArray= this.itemsSortInverse("name");
        //   this.setState({users:orderArray,orderShortName:"desc"});
        // }else{
        //   let orderArray=this.itemsSort("name");
        //   this.setState({users:orderArray,orderShortName:"asc"});
        // }
        // break;
        case "date":
        if (this.state.orderDate ==="asc"){
          let orderArray= this.itemsSortInverse("createdAt");
          this.setState({items:orderArray,orderDate:"desc"});
        }else{
          let orderArray=this.itemsSort("createdAt");
          this.setState({items:orderArray,orderDate:"asc"});
        }
        break;
        default:
        break;
      }

    }

    itemsSort =(itemToOrder) =>{
      let ascend = this.state.items.slice(); //copy array
      ascend.sort (function (a,b)
      {

        return a[itemToOrder].toString().localeCompare(b[itemToOrder].toString());
      }
    );
    return ascend;
  }

  itemsSortInverse = (itemToOrder) =>
  {
    var descend = this.state.items.slice();
    descend.sort (function (a, b)
    {

      return b[itemToOrder].toString().localeCompare(a[itemToOrder].toString());
    }
  );
  return descend;
}

openDialogForDelete = (item) => {
  this.setState({showDialogDelete:true, itemDelete:item});
}

closeDialogForDelete = () => {
  this.setState({showDialogDelete:false, itemDelete:""});
}

infoItem = () =>{
  if(this.state.itemInfo!==""){
    let item=this.state.itemInfo;
    let infoTitle="";
    let infoSubtype="";
    let infoDesc="";
    let infoLic="";
    let infoCre="";
    let infoLang="";
    let infoDate="";
    // let infoCreAt="";
    if(item.subtype.length>0)
    infoSubtype="Subtype: "+item.subtype.join();
    if(item.title!==""&& item.target.toUpperCase()!=="CRAWLED")
    infoTitle="Title: "+item.title;

    // if(item.title!=="")
    // infoTitle="Title: "+item.title;

    if(item.description!=="null123!")
    infoDesc="Description: "+item.description;

    if(item.license!=="null123!")
    infoLic="License: "+item.license;

    if(item.creator!=="null123!")
    infoCre="Creator: "+item.creator;

    if(item.language!=="null123!")
    infoLang="Language: "+item.language;

    if(item.date!=="null123!")
    infoDate="Date: "+item.date;

    // if(item.date!=="null123!")
    // infoCreAt="Created at: "+item.cratedAt;


    return (
      <div>
        <div><i style={{color:"#626262"}}> SEARCH PARAMETERS: </i></div><br/>
        {infoTitle}
        {/* {infoTitle!=="" ? <div>{infoTitle}</div> : <></> } */}
        {infoSubtype!=="" ? <div>{infoSubtype}</div> : <></> }
        {infoDesc!=="" ? <div>{infoDesc}</div> : <></> }
        {infoLic!=="" ? <div>{infoLic}</div> : <></> }
        {infoCre!=="" ? <div>{infoCre}</div> : <></> }
        {infoLang!=="" ? <div>{infoLang}</div> : <></> }
        {infoDate!=="" ? <div>{infoDate}</div> : <></> }
        {/* {infoCreAt!=="" ? <div>{infoCreAt}</div> : <></> } */}

        {item.target.toUpperCase()==="CRAWLED" &&
        infoDesc==="" &&
        infoCre==="" &&
        infoDate==="" && "No parameters sent"
      }
      </div>
    )
  }
}

render() {
  let jsonlang = this.props.appstate.App.language;

  return (
    <div style={{paddingTop: "30px"}}>

      <DialogAddSemanticSearch open={this.state.showAddDialog}
        onClose={this.closeAddSemantic}
        refreshCall={this.getAllSemanticSearch}
      />

      <DialogDeleteSemantic open={this.state.showDialogDelete}
        item={this.state.itemDelete}
        onClose={this.closeDialogForDelete}
        refreshCall={this.getAllSemanticSearch}
      />

      <AppBar  position="fixed" color="default">
        <Typography
          variant='h6'
          justify='center'
          align='center'
          >
            {jsonlang.semanticsearch.toUpperCase()}
          </Typography>

        </AppBar>
        <div>
          {/* tabella con la lista delle chiamate */}


        </div>
        {/*cambiare stringa*/}
        <Tooltip title={<h2>{jsonlang.adda.toUpperCase()}</h2>}>

        <Fab
          style={{
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed'}}
            role="button"
            color="primary"
            size="large"
            aria-label="Add"
            onClick={this.addSemantic}
            >
              <AddIcon
              />
            </Fab>

          </Tooltip>
          <div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="list search">
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center">
                    <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.name.toUpperCase()}</h2>}>
                    <Button value="source" onClick={this.order}>
                    SOURCE
                    <ImportExportIcon/>
                  </Button>
                </Tooltip>
              </TableCell> */}

              <TableCell align="center">
                <Tooltip title={<h2>ITEM TYPE</h2>}>
                <Typography variant="button" display="block" gutterBottom>
                  ITEM TYPE
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
              <Button value="date" onClick={this.order}>
                DATE
                <ImportExportIcon/>
              </Button>
            </Tooltip>
          </TableCell>


          {/* <TableCell align="center">
          <Tooltip title={<h2>SEARCH FIELD</h2>}>
          <Typography variant="button" display="block" gutterBottom>
          SEARCH FIELD
        </Typography>
      </Tooltip>
    </TableCell> */}



    {/* <TableCell align="center">
    <Tooltip title={<h2>FOUND</h2>}>
    <Typography variant="button" display="block" gutterBottom>
    FOUND
  </Typography>
</Tooltip>
</TableCell> */}



{this.props.appstate.App.user.role!=="superadmin"   ?
<TableCell align="center">
  <Tooltip title={<h2>ACTION</h2>}>
  <Typography variant="button" display="block" gutterBottom>
    ACTION
  </Typography>
</Tooltip>
</TableCell>

:<></>}


</TableRow>
</TableHead>
{this.state.items.map((item,index) =>
  <TableRow key={index} tabIndex="1">


    <TableCell align="center">
      <Tooltip title={<h2>{item.target.toUpperCase().toString()}</h2>} >
      <Typography color='default' className="ellips">
        {item.target.toUpperCase().toString()}
      </Typography>
    </Tooltip>
  </TableCell>

  <TableCell align="center">
    <Tooltip title={<h2>{new Date(item.createdAt).toUTCString().split(' ').slice(1).join(' ')}</h2>}>
    <Typography color='default' className="ellips">
      {new Date(item.createdAt).toUTCString().split(' ').slice(1).join(' ')}
    </Typography>
  </Tooltip>
</TableCell>

{/* <TableCell align="center">

<Tooltip title={<h2>SEARCH FIELD</h2>}>
<Typography
className="ellips"
//style={ this.getStatus(item.createdAt) === "running" ?{ color: "red" } :{  } }
>
{this.getStatus(item.createdAt) ==="running"? "RUNNING.." : "DONE"}
{item.subtype.join()}
</Typography>
</Tooltip>
</TableCell> */}


{/* <TableCell align="center">
<Typography variant="button" display="block" gutterBottom>
{item.title}
</Typography>
</TableCell> */}


<TableCell align="center">

  <Tooltip title={<h2>INFO</h2>}>
  <Button
    color="primary"
    onClick={(e)=>this.handleClickPopper(e,item)}
    >
      <InfoIcon/>
    </Button>
  </Tooltip>


  <Popper
    placement="left"
    modifiers={{
      offset: {
        enabled: true,
        offset: '0, 20'
      }
    }}
    open={this.state.anchorEl ?  true : false}
    anchorEl={this.state.anchorEl}
    style={{
      border: '1px solid #ccc',
      padding: "8px",
      backgroundColor: "#FFF",
    }}
    >

      <ClickAwayListener onClickAway={this.handleClickAway}>
      {this.infoItem(item)}
      </ClickAwayListener>
    </Popper>


    <Tooltip title={<h2>SHOW</h2>}>
    <Button
      color="primary"
      value={item._id}
      //  disabled={this.getStatus(item.createdAt) ==="running"? true : false}
      onClick={()=>this.AllSemantic(item)}
      >
        <VisibilityIcon/>
      </Button>
    </Tooltip>
    <Tooltip title={<h2>{jsonlang.remove.toUpperCase()}</h2>}>
    <Button
      color="primary"
      value={item._id}
      onClick={()=>this.openDialogForDelete(item)}
      >
        <DeleteForeverIcon/>
      </Button>

    </Tooltip>
  </TableCell>


</TableRow>
)}
</Table>
</TableContainer>
</div>





</div>
)
}
}

export default connect(mapStateToProps)(SemanticSearch);
