import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";

import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
// import { APP } from '../../redux/actions/actions';
import { postService, postServiceCERTH} from "../../services/services";
import * as services from "../../services/services.json";

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const typeSocial = [{
  value:"YOUTUBE",
  label:"Youtube"
},
{
  value:"TWITTER",
  label:"Twitter"
},
{
  value:"DIGITALMEETS",
  label:"Digital meets culture"
},
{
  value:"AMNESTY",
  label:"Amnesty International"
},
{
  value:"COURAGEOPPOSITION",
  label:"Courage cultural opposition"
}];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogAddSocialMedia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog:false,
      valueSocial:"",
      videoCount:"20",
      arguments:"",
      arrayArguments:[],
      loading:false,
    }
  }
  componentDidMount() {

  }

  onclose = () => {
    this.setState({valueSocial:"",
    videoCount:"20",
    arguments:""});
    this.props.onClose();

  }

  onChangeSelect = (event) => {
    //  console.log(event);
    this.setState({valueSocial:event.target.value});
  }

  onChangeArguments = (event) => {
    //  console.log(event.target.value);

    //deve essere un array
    const chars = event.target.value.split(',');
    this.setState({arguments:event.target.value, arrayArguments:chars});
  }

  onChangeVideoCount = (event) => {
    //  console.log(event.target.value);
    //this.setState({videoCount:event.target.value});
    this.setState({videoCount:parseInt(event.target.value)})

  }





  search = () => {
    //chiamata CERTH e salvataggio in locale
    //<nome_social>_<nomeUtente>_timestamp

    let self = this;
    //prima la chiamata a CERTH, poi al nostro db
    //inizio loading
    this.setState({loading:true});
    let search_name="";
    if(this.state.valueSocial!=="TWITTER"&&this.state.valueSocial!=="YOUTUBE")
      search_name= this.props.appstate.App.user.userid+"_"+ this.state.valueSocial+"_"+Date.now();
    else
      search_name= this.state.valueSocial+"_"+this.props.appstate.App.user.userid+"_"+Date.now();


      let serviceurl = process.env.REACT_APP_DOMAIN + services.socialmedia_POST_CERTH;
    postServiceCERTH(
      serviceurl,
      {
        keywords:this.state.arrayArguments,
        video_count: this.state.videoCount,
        search_name:search_name,
        service:this.state.valueSocial.toUpperCase()
      },
      function (err, result) {
        //console.log("err certh" +err);
        //console.log("result certh" +result);
        if (err) {
          return;
        }

        else {
          // console.log("result certh" +result);
          postService(
            process.env.REACT_APP_DOMAIN + services.socialmedia_POST,
            {
              appname:self.props.appstate.App.name,
              domain:self.props.appstate.App.user.domain,
              owner: self.props.appstate.App.user.userid,
              source: self.state.valueSocial,
              keywords: self.state.arrayArguments,
              video_count: self.state.videoCount,
              search_name: search_name
            },
            function (err, result) {
              // console.log("result db" +result);
              // console.log("error db" +err);
              if (err) {
                return;
              }
              if (result.status === 204) {
                self.props.callback();
                return;
              }
              //self.props.history.go(0);
              //fine loading
              self.setState({loading:false});
              //chiusura dialog
              self.onclose();
              self.props.refreshCall();
              //  self.props.callback();
            }
          );

        }
        //self.props.callback();
      }
    );


  }


  getDisabledSearch = () => {
    if(this.state.valueSocial.toUpperCase()==="TWITTER" && this.state.arguments.length===0)
      return true;
    else if(this.state.valueSocial.toUpperCase()==="YOUTUBE" && (this.state.arguments.length===0 || this.state.videoCount===""))
      return true;
    else if(this.state.valueSocial==="")
      return true;
    else return false;
  }


  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        {this.state.loading ?
          <Dialog
            TransitionComponent={Transition}
            open={this.props.open}
            onClose={this.onclose}
            fullWidth
            maxWidth={'sm'}
          >
            <div style={{
                textAlign:"center",
                padding:"10px"
            }} >
              <CircularProgress
              />
            </div>
          </Dialog> :

          <Dialog
            TransitionComponent={Transition}
            open={this.props.open}
            onClose={this.onclose}
            aria-labelledby="dialog-title"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle id="dialog-remove">
              <SearchIcon
                aria-label="Search info"
                style={{
                    color: "#fff",
                    backgroundColor: "#3f51b5",
                    borderRadius: "50px",
                    fontSize: "36px",
                    padding: "5px",
                }}
              />
              <span style={{
                  padding: 5,
                  position: 'fixed',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  //  width: '55vmin'
              }}>
                {/*}        {jsonlang.remove.toUpperCase() + " " + this.props.appstate.Catalog.resourceInfo.name}  */}
                {jsonlang.newsearchsm.toUpperCase()}
              </span>
            </DialogTitle>
            <DialogContent dividers>

              <div>
                <Grid container spacing={1}>

                  <Grid item xs={6}>
                    <TextField
                      select
                      required
                      color="primary"
                      // placeholder={jsonlang.country}
                      //  label={jsonlang.country.toUpperCase()}
                      label="SOURCE"
                      variant="outlined"
                      fullWidth
                      value={this.state.valueSocial}
                      onChange={this.onChangeSelect}
                      name="socialmedia"
                      //  error={this.state.countryerror}

                      >
                        {typeSocial.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>


                    </Grid>
                    {this.state.valueSocial!= null && this.state.valueSocial.toUpperCase()==="YOUTUBE" ?
                    <Grid item xs={6}>

                      <TextField
                        error={this.state.videoCount>1000 ? true : false}
                        color="primary"
                        type="number"
                        // placeholder={jsonlang.instshortname}
                        //  label={jsonlang.instshortname.toUpperCase()}
                        InputProps={{ inputProps: { min: "0", max: "1000", step: "1" } }}
                        label="MAX COUNT 1000"
                        defaultValue={this.state.videoCount}
                        value={this.state.videoCount}
                        variant="outlined"
                        fullWidth
                        required //valido solo per label
                        // error={this.state.shortnameerror}
                        onChange={this.onChangeVideoCount}
                        name="video_count"
                      />
                    </Grid>
                    : null}

                    {this.state.valueSocial!= null && (this.state.valueSocial.toUpperCase()==="YOUTUBE" || this.state.valueSocial.toUpperCase()==="TWITTER") ?

                    <Grid item xs={12}>
                      <TextField
                        color="primary"
                        fullWidth
                        label={this.state.valueSocial!= null && this.state.valueSocial.toUpperCase()==="YOUTUBE" ?
                        "Arguments ex: reefugees"
                        :
                        this.state.valueSocial.toUpperCase()==="TWITTER" ?
                        "Arguments ex: reefugees, war"
                        : null}
                        variant="outlined"
                        required
                        onChange={this.onChangeArguments}
                        name="Arguments"
                      />
                    </Grid>
                    : null}
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.onclose}
                  color="primary"
                  startIcon={<ClearIcon />}>
                  <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                  <Typography variant="button">{jsonlang.instcancel.toUpperCase()}</Typography>
                </Tooltip>
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={this.search}
                color="primary"
                disabled={this.getDisabledSearch()}
                startIcon={<DoneIcon />}>
                <Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
                <Typography variant="button">{jsonlang.search.toUpperCase()}</Typography>
              </Tooltip>
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  )
}
}
export default connect(mapStateToProps)(DialogAddSocialMedia);
