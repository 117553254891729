export function getRights(right) {
  if (right) {
    switch (right.toUpperCase()) {
      case "ALL":
        let rights = [
          { "id": "01", "value": "CC0", "uri": "https://creativecommons.org/publicdomain/zero/1.0/", "logo": "images/rights/cc-zero.png" },
          { "id": "02", "value": "CC-BY-4.0", "uri": "https://creativecommons.org/licenses/by/4.0/", "logo": "images/rights/by.png" },
          { "id": "03", "value": "CC-BY-SA", "uri": "https://creativecommons.org/licenses/by-sa/4.0/", "logo": "images/rights/by-sa.png" },
          { "id": "04", "value": "CC-BY-ND", "uri": "https://creativecommons.org/licenses/by-nd/4.0/", "logo": "images/rights/by-nd.png" },
          { "id": "05", "value": "CC-BY-NC", "uri": "https://creativecommons.org/licenses/by-nc/4.0/", "logo": "images/rights/by-nc.png" },
          { "id": "06", "value": "CC-BY-NC-SA", "uri": "https://creativecommons.org/licenses/by-nc-sa/4.0/", "logo": "images/rights/by-nc-sa.png" },
          { "id": "07", "value": "CC-BY-NC-ND", "uri": "https://creativecommons.org/licenses/by-nc-nd/4.0/", "logo": "images/rights/by-nc-nd.png" },
          { "id": "08", "value": "IN COPYRIGHT", "uri": "http://rightsstatements.org/vocab/InC/1.0/", "logo": "images/rights/InC.png" },
          { "id": "09", "value": "IN COPYRIGHT - EU ORPHAN WORK", "uri": "http://rightsstatements.org/vocab/InC-OW-EU/1.0/", "logo": "images/rights/InC-OW-EU.png" },
          { "id": "11", "value": "IN COPYRIGHT - EDUCATIONAL USE PERMITTED", "uri": "http://rightsstatements.org/vocab/InC-EDU/1.0/", "logo": "images/rights/InC-EDU.png" },
          { "id": "12", "value": "IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED", "uri": "http://rightsstatements.org/vocab/InC-NC/1.0/", "logo": "images/rights/InC-NC.png" },
          { "id": "13", "value": "IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE", "uri": "http://rightsstatements.org/vocab/InC-RUU/1.0/", "logo": "images/rights/InC-RUU.png" },
          { "id": "14", "value": "COPYRIGHT NOT EVALUATED (CNE)", "uri": "https://rightsstatements.org/vocab/CNE/1.0/", "logo": "images/rights/CNE.png" },
          { "id": "15", "value": "PUBLIC DOMAIN MARC PDM", "uri": "https://creativecommons.org/publicdomain/mark/1.0/", "logo": "images/rights/Public_Domain_Mark.png" },
          { "id": "16", "value": "NO COPYRIGHT - NON COMMERCIAL RE-USE ONLY (NOC-NC)", "uri": "https://rightsstatements.org/vocab/NoC-NC/1.0/", "logo": "images/rights/NoC-NC.png" },
          { "id": "17", "value": "NO COPYRIGHT - OTHER KNOWN LEGAL RESTRICTION (NOC-OKLR)", "uri": "https://rightsstatements.org/vocab/NoC-OKLR/1.0/", "logo": "images/rights/NoC-OKLR.png" },

        ];
        return rights;
      case "CC-BY-ND":
        return { "id": "04", "value": "CC-BY-ND", "uri": "https://creativecommons.org/licenses/by-nd/4.0/", "logo": "images/rights/by-nd.png" };
      case "CC-BY-NC":
        return { "id": "05", "value": "CC-BY-NC", "uri": "https://creativecommons.org/licenses/by-nc/4.0/", "logo": "images/rights/by-nc.png" };
      case "CC-BY-NC-SA":
        return { "id": "06", "value": "CC-BY-NC-SA", "uri": "https://creativecommons.org/licenses/by-nc-sa/4.0/", "logo": "images/rights/by-nc-sa.png" };
      case "CC-BY-NC-ND":
        return { "id": "07", "value": "CC-BY-NC-ND", "uri": "https://creativecommons.org/licenses/by-nc-nd/4.0/", "logo": "images/rights/by-nc-nd.png" };
      case "IN COPYRIGHT":
        return { "id": "08", "value": "IN COPYRIGHT", "uri": "http://rightsstatements.org/vocab/InC/1.0/", "logo": "images/rights/copyright.png" };
      case "CC-BY-SA":
        return { "id": "03", "value": "CC-BY-SA", "uri": "https://creativecommons.org/licenses/by-sa/4.0/", "logo": "images/rights/by-sa.png" };
      case "CC-BY-4.0":
        return { "id": "02", "value": "CC-BY", "uri": "https://creativecommons.org/licenses/by/4.0/", "logo": "images/rights/by.png" };
      case "CC0":
        return { "id": "01", "value": "CC0", "uri": "https://creativecommons.org/publicdomain/zero/1.0/", "logo": "images/rights/cc-zero.png" };
      case "IN COPYRIGHT - EU ORPHAN WORK":
      return  { "id": "09", "value": "IN COPYRIGHT - EU ORPHAN WORK", "uri": "http://rightsstatements.org/vocab/InC-OW-EU/1.0/", "logo": "images/rights/InC-OW-EU.png" };
      // case "CC-BY-3.0":
      // return  { "id": "10", "value": "CC-BY-3.0", "uri": "https://creativecommons.org/licenses/by/4.0/", "logo": "images/rights/by.png" };
      case "IN COPYRIGHT - EDUCATIONAL USE PERMITTED":
      return { "id": "11", "value": "IN COPYRIGHT - EDUCATIONAL USE PERMITTED", "uri": "http://rightsstatements.org/vocab/InC-EDU/1.0/", "logo": "images/rights/InC-EDU.png"};
      case "IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED":
      return { "id": "12", "value": "IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED", "uri": "http://rightsstatements.org/vocab/InC-NC/1.0/", "logo": "images/rights/InC-NC.png" };
      case "IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE":
      return { "id": "13", "value": "IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE", "uri": "http://rightsstatements.org/vocab/InC-RUU/1.0/", "logo": "images/rights/InC-RUU.png" };
      case "COPYRIGHT NOT EVALUATED (CNE)":
      return {"id": "14", "value": "COPYRIGHT NOT EVALUATED (CNE)", "uri": "https://rightsstatements.org/vocab/CNE/1.0/", "logo": "images/rights/CNE.png"};
      case "PUBLIC DOMAIN MARC PDM":
      return { "id": "15", "value": "PUBLIC DOMAIN MARC PDM", "uri": "https://creativecommons.org/publicdomain/mark/1.0/", "logo": "images/rights/Public_Domain_Mark.png" };
      case "NO COPYRIGHT - NON COMMERCIAL RE-USE ONLY (NOC-NC)":
      return { "id": "16", "value": "NO COPYRIGHT - NON COMMERCIAL RE-USE ONLY (NOC-NC)", "uri": "https://rightsstatements.org/vocab/NoC-NC/1.0/", "logo": "images/rights/NoC-NC.png" };
      case "NO COPYRIGHT - OTHER KNOWN LEGAL RESTRICTION (NOC-OKLR)":
      return { "id": "17", "value": "NO COPYRIGHT - OTHER KNOWN LEGAL RESTRICTION (NOC-OKLR)", "uri": "https://rightsstatements.org/vocab/NoC-OKLR/1.0/", "logo": "images/rights/NoC-OKLR.png" };
      default:
        return { "id": "01", "value": "CC0", "uri": "https://creativecommons.org/publicdomain/zero/1.0/", "logo": "images/rights/cc-zero.png" };
    }
  }
  return { "id": "01", "value": "CC0", "uri": "https://creativecommons.org/publicdomain/zero/1.0/", "logo": "images/rights/cc-zero.png" };
}




export function  itemsSort (itemToOrder , dataArray) {
  let ascend = dataArray.slice();
  ascend.sort (function (a,b)
  {
    return a[itemToOrder].localeCompare(b[itemToOrder]);
  }
);
return ascend;
}

export function  itemsSortInverse (itemToOrder,dataArray){

  var descend = dataArray.slice();

  descend.sort (function (a, b)
  {
    return b[itemToOrder].localeCompare(a[itemToOrder]);
  }
);
return descend;
}


//ordinamenti di date in ms
export function  itemsSortDate (itemToOrder , dataArray) {
  let ascend = dataArray.slice();
  ascend.sort (function (a,b)
  {
      return new Date(a[itemToOrder]) - new Date(b[itemToOrder]);
    // return a[itemToOrder].localeCompare(b[itemToOrder]);
  }
);
return ascend;
}


export function  itemsSortInverseDate (itemToOrder,dataArray){

  var descend = dataArray.slice();

  descend.sort (function (a, b)
  {
    return new Date(b[itemToOrder]) - new Date(a[itemToOrder]);

  }
);
return descend;
}


export function getExternalMediaType (){
  return[
    {"id":"0","value":"imagecloud","label":"IMAGE"},
    {"id":"1","value":"audiocloud","label":"AUDIO"},
    {"id":"2","value":"videocloud","label":"VIDEO"},
    {"id":"3","value":"filecloud","label":"FILE"},
    {"id":"4","value":"webcloud","label":"WEB"},
    {"id":"5","value":"documentcloud","label":"DOCUMENT"},
    //{"id":"6","value":"textcloud","label":"TEXT"},
  ];
}

export function getResourceDataForLanguage(data,explicitLanguage){
  // console.log("lingua del browser selezionato : ")
  // console.log(navigator.language.substring(0,2))
  let lan=explicitLanguage ?explicitLanguage : navigator.language.substring(0,2) || navigator.userLanguage.substring(0,2) ;
  const lanList = Object.keys(data.medialan);
  // print all keys
   // console.log(lanList);
  if (lanList.includes(lan)){
    return lan ;
  }else if (lanList.includes("en")){
    return "en";
  }else if (lanList.includes("it")){
    return "it";
  }else {
    return lanList[0];
  }
}
