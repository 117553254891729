import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // CircularProgress,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography
} from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import { deleteService} from "../../services/services";
import * as services from "../../services/services.json";

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



class DialogDeleteSemantic extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {

  }

  onclose = (event, reason)  => {
    if (reason && reason == "backdropClick")
    return;
    this.props.onClose();
  }

  deleteconfirm = () => {
    let self = this;
      deleteService(
        process.env.REACT_APP_DOMAIN + services.semanticsearch_DELETE,
        { id: this.props.item._id, appname: this.props.appstate.App.name, domain: this.props.appstate.App.user.domain },
        function (err, result) {
          if (err) {
            return;
          }
          // console.log(result.status);
          if (result.status === 204) {

            self.onclose();
            self.props.refreshCall();
            return;
          }
          self.onclose();
          self.props.callback();
        }
      );

  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        <Dialog
          TransitionComponent={Transition}
          open={this.props.open}
          onClose={this.onclose}
          aria-labelledby="dialog-title"
          fullWidth
          disableEscapeKeyDown
          maxWidth={'sm'}
          >
            <DialogTitle id="dialog-remove">
              <DeleteIcon
                aria-label="Delete info"
                style={{
                  color: "#fff",
                  backgroundColor: "#3f51b5",
                  borderRadius: "50px",
                  fontSize: "36px",
                  padding: "5px",
                }}
              />
              <span style={{
                padding: 5,
                position: 'fixed',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '55vmin'
              }}>
              {jsonlang.remove.toUpperCase() + " "}
              {this.props.item !=="" ? this.props.item.search_name : ""}
            </span>
          </DialogTitle>
          <DialogContent dividers>
            <div>




            </div>
            <Typography
              variant='h6'
              justify='center'
              align='center'
              >
                {jsonlang.messageconfirm.toUpperCase()}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>{jsonlang.no.toUpperCase()}</h2>}>
                <Typography variant="button">{jsonlang.no.toUpperCase()}</Typography>
              </Tooltip>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={this.deleteconfirm}
              color="primary"
              startIcon={<DoneIcon />}>
              <Tooltip title={<h2>{jsonlang.yes.toUpperCase()}</h2>}>
              <Typography variant="button">{jsonlang.yes.toUpperCase()}</Typography>
            </Tooltip>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
}
export default connect(mapStateToProps)(DialogDeleteSemantic);
