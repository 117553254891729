import { createStore } from "redux";
import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native
import { persistStore, persistCombineReducers } from "redux-persist";
import reducers from "../redux/statecore";

const config = {
  key: "asseteng2021",
  storage,
};

const statecore = persistCombineReducers(config, reducers);
export const configureStore = function configureStore(callback) {
  const store = createStore(
    statecore /* preloadedState, */,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  persistStore(store, {}, () => {
    callback(store, persistStore);
  });
};
