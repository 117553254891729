// import * as services from "./services.json";
import Axios from "axios";

export function postService(service, inputdata, callback) {


        let headers="";
          headers = { headers: { "content-type": "application/json" } };

    Axios.post(service, inputdata, headers)
        .then(function (response) {

            if (response.status === 201) {
              // console.log("postService"+response.status);
              // console.log("postService"+response.data.message);
                callback(undefined, {
                    status: response.status,
                    response: response.data.message,
                });
                return;
            }
            // console.log("postService");
            callback(undefined, {
                status: response.status,
                response: undefined,
            });

        })
        .catch(function (error) {
          // console.log("postService");
             callback(error, undefined);
        });
}


export function getService(querystring, callback) {

    Axios.get(
        querystring
    )
        .then(function (response) {
            if (response.status === 200) {
                callback(undefined, { status: response.status, response: response.data.message });
                return;
            }
            callback(undefined, {
                status: response.status,
                response: undefined,
            });
            return;
        })
        .catch(function (error) {
            callback(error, undefined);

            return;
        });
}

export function deleteService(service, inputdata, callback) {

    Axios({
      method: "delete",
      url: service,
      data: inputdata //json
    })
      .then(function (response) {
          if (response.status === 204) {
                            callback(undefined, {
                              status: response.status,
                              response: response.data.message,
                            });
              return;
          }
           callback(undefined, {
             status: response.status,
             response: undefined,
           });
          return;
      })
        .catch(function (error) { callback(error, undefined);});
}


export function postServiceCERTH(service, inputdata, callback) {
//  var basicAuth = 'Basic ' + btoa("evan" + ':' + "mklab");
var data = JSON.stringify(inputdata);
// console.log(data);

        Axios.post(service, inputdata, {}
      ).then(function(response){
                //  if (response.status === 200) {
                    // console.log("ok 200"+ JSON.stringify(response));
                          callback(undefined, response);
                          return;
                  //    }
          }).catch(function (error) {
            // console.log("not ok 200 "+ error);
                      callback(error, undefined);
                      return;
          });



}
