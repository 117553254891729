const initialState = {
  "app": "SOCLOSE",
  "languages": "IT,ES,FR",
  "configuration": {
    "title": "titolo applicazione",
    "tabtitle": "Titolo nel tab",
    "logo": {
      "light": "",
      "dark": "",
      "tab": ""
    },
    "Home": {
      "content": "Prova"
    },
    "Header": {
      "current": "home"
    },
    "Theme": {
      "currenttheme": "light",
      "themelink": "notheme",
      "themefont": "fontnormal"
    },
    "Languages": {
      "default": "en",
      "list": [
        {
          "code": "en",
          "label": "EN - English",
          "icon": ""
        },
        {
          "code": "it",
          "label": "IT - Italiano",
          "icon": ""
        },
        {
          "code": "es",
          "label": "ES - Español",
          "icon": ""
        },
        {
          "code": "gr",
          "label": "GR - Ελληνικά",
          "icon": ""
        },
        {
          "code": "pl",
          "label": "PL - Polskie",
          "icon": ""
        }
      ]
    }
  }
};

const statecore = (state = initialState, action) => {
  switch (action.type) {
    case "WHOLE":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const appState = {
  App: {
    name: "soclose",
    tab: "1",
    user: {
      logged: false,
      userid: "",
      token: "token di sessione",
      domain: "",
      name: "",
      role: "basic",
      email: "",
      status: "",
      avatar: "",
      createdOn: "",
      domainDetail: {}
    },
    currentappchild: "http://localhost:3001",
    accessibility: {
      valuefont: "fontnormal",
      valuecontrast: "light",
      valuecolored: "colornormal",
      valuelinks: "linksdefault"
    },
    currentLanguage: "en",
    language: {
      login: "login",
      registration: "registration"
    },
    theme: {
      themecontrast: {},
      themelink: {},
      valuethemefirst: "light",
      valuethemelink: "notheme",
      refmaterial: {}
    }
  },
  Modal: {
    openModalLoading: false,
    openModalLoadingText: "",
    openModalLoadingTextActive: false,
    openModalDelete: false,
    openModalLanguage: false,
    openModalAccessibility: false,
    openModalType: ""
  },
  Resource: {
    openModalResources: false,
    openModalIconLang: false,
    resources: [],
    index: 0, // current index
    id: 0 //current _id
  },
  ResourceManager: {
    openModalResources: false,
    openModalIconLang: false,
    resources: [],
    index: 0, // current index
    id: 0, //current _id
    render: false,
    resourceInfo: {}
  },
  ModifyDomDialog: {
    open: false,
    data: {}
  },
  CreateDomDialog: {
    open: false,
  },
  YesNoDialog: {
    open: false,
    message: "",
    title: "",
  },
  InputAndActionDialog: {
    open: false,
    contentinput: "",
    title: "",
  },
  PasswordModifyDialog: {
    open: false,
    contentinput: "",
    title: "",
  },
  MapDialog: {
    position: { lat: 0, lng: 0 },
    openModalMap: false,
    render: false,
  },
  PreviewDialog: {
    openModalPreview: false,
    item: {},
    itemurl: "",
    render: false,
    qrcode: false,
    share: false
  },
  StoryEditor: {
    startLangForTrad:"",
    language:"en",
    translation:false
  },
  Catalog: {
    openModalResources: false,
    openModalIconLang: false,
    resources: [],
    index: 0, // current index
    id: 0, //current _id
    render: false,
    edit:false,
    resourceInfo: {}
  }
};

const statecoreapp = (state = appState, action) => {
  switch (action.type) {
    case "APP":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export default {
  acamar: statecore,
  appstate: statecoreapp
};
