import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from "./components/Home";
import DetailSocialMedia from "./components/SocialMedia/DetailSocialMedia";
import SemanticSearch from "./components/SemanticSearch/SemanticSearch";
import DetailSemanticSearch from "./components/SemanticSearch/DetailSemanticSearch";
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { configureStore } from "./store/store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  createMuiTheme,
  ThemeProvider,
  //responsiveFontSizes
} from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { WHOLE, APP } from './redux/actions/actions';
import load from 'little-loader';

configureStore(function (store, persistor) {
  const Main = () => (
    <>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} store={store} />} />
            <Route exact path="/AllSocial" render={(props) => <DetailSocialMedia {...props} store={store} />} />
            <Route exact path="/Semantic" render={(props) => <SemanticSearch {...props} store={store} />} />
            <Route exact path="/AllSemantic" render={(props) => <DetailSemanticSearch {...props} store={store} />} />
          </Switch>
        </Router>
      </Provider>
    </>
  );
  initApp();
  function renderRefresh() {
    ReactDOM.render(
      <ThemeProvider
        theme={
          store.getState().appstate.App.theme.refmaterial
        }
      >  <Main />
        {/*store.getState().appstate.App.theme.valuethemelink === "yellow" ?
          <ThemeProvider
            theme={(outherTheme) => ({
              ...outherTheme, ...window.getYellowTheme()
            })}>
            <CssBaseline />
            <Main />
          </ThemeProvider>
          :
          store.getState().appstate.App.theme.valuethemelink === "bothyelunder" ?
            <ThemeProvider
              theme={(outherTheme) => ({
                ...outherTheme, ...window.getBothYelUnderTheme()
              })}>
              <CssBaseline />
              <Main />
            </ThemeProvider>
            :
            store.getState().appstate.App.theme.valuethemelink === "underline" ?
              <ThemeProvider
                theme={(outherTheme) => ({
                  ...outherTheme, ...window.getUnderlineTheme()
                })}>
                <CssBaseline />
                <Main />
              </ThemeProvider>
              :
              <ThemeProvider
                theme={(outherTheme) => ({
                  ...outherTheme, ...window.getNoTheme()
                })}>
                <CssBaseline />
                <Main />
              </ThemeProvider>
        */}
      </ThemeProvider>,
      document.getElementById("root"),
      () => {
        //ReactDOM.render(<RouterContent />, document.getElementById("content"));
      }
    );
  }

  function initApp() {
    window.addEventListener('message', postMessageHandler);
  }

  function postMessageHandler (event) {
     // console.log("postMessageHandler" +event.data.type)
      // console.log("postMessageHandler" +event.data.appstate)

    if (event.origin === process.env.REACT_APP_WEBSTART) {
      switch (event.data.type) {
        case "WHOLE":
          let currentconfig = Object.assign({},event.data.appstate);
          // console.log("postMessageHandler" +currentconfig.App.name)

      //     console.log(currentconfig.App.user.preferences.accessibility.fontSize + " "+  currentconfig.App.accessibility.valuefont);
      //   console.log(currentconfig.App.user.preferences.accessibility.familyFont + " "+  currentconfig.App.accessibility.valuefamilyfont);
      //   console.log(currentconfig.App.user.preferences.accessibility.contrast + " "+  currentconfig.App.accessibility.valuecontrast);
      // console.log(currentconfig.App.user.preferences.accessibility.underlineLinks + " "+  currentconfig.App.accessibility.valuelinks);
      // console.log(currentconfig.App.user.preferences.accessibility.yellowLinks + " "+  currentconfig.App.accessibility.valuecolored);



        load(process.env['REACT_APP_THEMEFIRST_'+currentconfig.App.name], function (err) {
          console.log("load 1");
            if(currentconfig.App.accessibility.valuecontrast==="dark"){
            currentconfig.App.theme.themecontrast = window.getDarkTheme();
          }
            else {
              currentconfig.App.theme.themecontrast = window.getLightTheme();
          }
          //currentconfig.App.theme.themecontrast = window.getLightTheme();
        //  currentconfig.App.theme.refmaterial = createMuiTheme(currentconfig.App.theme.themecontrast);

            load(process.env['REACT_APP_THEMELINK_'+currentconfig.App.name], function (err) {
              console.log("load 2" )
              if(currentconfig.App.accessibility.valuecontrast==="light" && currentconfig.App.accessibility.valuefamilyfont==="Lexend"){
              //if (currentconfig.App.theme.valuethemefirst === "Lexend" ) {
                currentconfig.App.theme.themecontrast = window.getLexend();
                currentconfig.App.theme.refmaterial = createMuiTheme(currentconfig.App.theme.themecontrast);
              }
              else if(currentconfig.App.accessibility.valuecontrast==="dark" && currentconfig.App.accessibility.valuefamilyfont==="Lexend")  {
                currentconfig.App.theme.themecontrast = window.getLexendDark();
                currentconfig.App.theme.refmaterial = createMuiTheme(currentconfig.App.theme.themecontrast);

              }
              else  currentconfig.App.theme.refmaterial = createMuiTheme(currentconfig.App.theme.themecontrast);


              //link
              // if (currentconfig.App.theme.valuethemelink === "yellow") {
              //    currentconfig.App.theme.themelink = window.getYellowTheme();
              // } else if (currentconfig.App.theme.valuethemelink === "bothyelunder") {
              //   currentconfig.App.theme.themelink = window.getBothYelUnderTheme();
              // }else if (currentconfig.App.theme.valuethemelink === "notheme") {
              //   currentconfig.App.theme.themelink = window.getNoTheme();
              // } else if (currentconfig.App.theme.valuethemelink === "underline") {
              //   currentconfig.App.theme.themelink = window.getUnderlineTheme();
              // }

            //   if (currentconfig.App.user.preferences.accessibility.familyFont === "Lexend" &&
            // currentconfig.App.accessibility.valuecontrast==="light") {
            //     currentconfig.App.theme.valuethemefirst = "Lexend";
            //   }else
            //   if (currentconfig.App.user.preferences.accessibility.familyFont === "Lexend" &&
            // currentconfig.App.accessibility.valuecontrast==="dark") {
            //     currentconfig.App.theme.valuethemefirst = "LexendDark";
            //   }
            //   else if (currentconfig.App.user.preferences.accessibility.familyFont === "familydefault") {
            //     if(currentconfig.App.accessibility.valuecontrast==="dark"){
            //     currentconfig.App.theme.valuethemefirst = "dark";
            //   }
            //     else {
            //       currentconfig.App.theme.valuethemefirst = "light";
            //     }
            //   }

              /*if(currentconfig.App.accessibility.valuecolored==="coloryellow"){
                if (currentconfig.App.accessibility.valuelinks==="linksdefault"){
                  currentconfig.App.theme.valuethemelink="yellow";
                  currentconfig.App.theme.themelink = window.getYellowTheme();
                }
                else{
                  currentconfig.App.theme.valuethemelink="bothyelunder";
                  currentconfig.App.theme.themelink = window.getBothYelUnderTheme();
                }
              }
              else if(currentconfig.App.accessibility.valuecolored==="colornormal"){
                if (currentconfig.App.accessibility.valuelinks==="linksdefault"){
                  currentconfig.App.theme.valuethemelink="notheme";
                  currentconfig.App.theme.themelink = window.getNoTheme();
                }
                else{
                  currentconfig.App.theme.valuethemelink="underline";
                  currentconfig.App.theme.themelink = window.getUnderlineTheme();
                }
              }
              else if(currentconfig.App.accessibility.valuelinks==="linksunderline"){
                if (currentconfig.App.accessibility.valuecolored==="coloryellow"){
                  currentconfig.App.theme.valuethemelink="bothyelunder";
                  currentconfig.App.theme.themelink = window.getBothYelUnderTheme();
                }
                else{
                  currentconfig.App.theme.valuethemelink="underline";
                  currentconfig.App.theme.themelink = window.getUnderlineTheme();
                }
              } else{
                if (currentconfig.App.accessibility.valuecolored==="coloryellow"){
                  currentconfig.App.theme.valuethemelink="yellow";
                  currentconfig.App.theme.themelink = window.getYellowTheme();
                }
                else{
                  currentconfig.App.theme.valuethemelink="notheme";
                  currentconfig.App.theme.themelink = window.getNoTheme();
                }

              }*/

               store.dispatch(APP(currentconfig));
               store.dispatch(WHOLE(event.data.acamar));
              renderRefresh();
            });
          });

          //let currentconfig = store.getState().appstate;
          break;
        case "FONT":
          var fontList = [
            'fontnormal',
            'fontlarge',
            'fontxlarge',
            'fontxxlarge'
          ];
          if(document.documentElement.classList.length===0){
            document.documentElement.classList.add(event.data.font);
            return;
          }

          for(let i=0; i<fontList.length; i++){

            if (fontList[i] === event.data.font)
            document.documentElement.classList.replace(document.documentElement.classList[0], fontList[i]);
            else {
              document.documentElement.classList.add(event.data.font);
            }

          }

        
          break;
        default: break;
      }
    }
  }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
