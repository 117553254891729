import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // Box,
  // Button,
  // CircularProgress,
  // Dialog,
  Checkbox,
  // CheckBoxOutlineBlankIcon,
  // CheckBoxIcon,
  Divider,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  FormGroup,
  FormControlLabel,
  FormControl,
  // FormLabel,
  Grid,
  // IconButton,
  Input,
  // InputLabel,
  // Link,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  // Tab,
  // Tabs,
  TextField,
  // Tooltip,
  Typography
} from "@material-ui/core";


import { getRights } from "../../Utils/utility";
import languages from '../../config/languages.json';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class ResearchMatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rights:getRights("all"),
      selectedRight:getRights("CC0").value,
      linkRight:getRights("CC0").uri,
      dataLanguage:this.props.appstate.App.currentLanguage.toLowerCase(),
      selectLanguage:[],
      codeSelectLanguage:[],
      targetValue: "atomic",

      checkedAllAtomic:false,
      checked3dobj:false,
      checkedAudio:true,
      checkedImage:false,
      checkedImage360:false,
      checkedVideoFlat:false,
      checkedVideo360:false,

      checkedStorymap:true,
      checkedWebdoc:false,
      checkedExibition:false,
      checkedAllComplex:false,


      searcherror:true,
      searcherrorDesc:true,

      checkedDescription:false,
      checkedLicense:false,
      checkedLanguage:false,
      checkedCreator:false,
      checkedCreated:false,

      typesearch:"MATCHING",
      target:"atomic",
      subtype:[],
      subtypeAtomic:["audio"],
      subtypeComplex:["storymap"],
      title: "",
      description:"",
      license:"",
      creator:"",
      language:"",
      date:"",

      message:'',

    }

  }
  componentDidMount() {

  }

  onChangeselectLanguage = (event) =>{
    // console.log(event);
    const options = event.target;
    // console.log(event.target);
    //    console.log(options);
    const value = [];
    let valueCode = [];

    for (let i = 0, l = options.value.length; i < l; i += 1) {

      value.push(options.value[i]);
      //  console.log(options.value[i]);
      var filteredObj = languages.filter(obj => obj.name === options.value[i]);
      // console.log(filteredObj);
      valueCode.push(filteredObj[0].code);
    }
    // this.setState({selectLanguage:value, codeSelectLanguage:valueCode}, console.log(this.state.selectLanguage,this.state.codeSelectLanguage));
    this.setState({selectLanguage:value,codeSelectLanguage:valueCode,
      language:valueCode},
      this.props.data({
        typesearch:this.state.typesearch,
        target:this.state.target,
        subtype:this.state.target ==="atomic" ? this.state.subtypeAtomic : this.state.target ==="complex" ? this.state.subtypeComplex : [],
        title:this.state.title,
        description:this.state.description,
        license:this.state.license,
        creator:this.state.creator,
        language:valueCode,
        date:this.state.date
      }));


    }

    getListSubtypeAtomic = (event) =>{
      let list =   this.state.subtypeAtomic;

      switch(event.target.name){
        case "checkedAllAtomic":
        if(event.target.checked===true){
          return ["all"];
        }else return ["audio"];
        case "checked3dobj":
        if(event.target.checked===true){
          list.push("3d-object");
        }else {
          const index = list.indexOf("3d-object");
          if (index > -1) {
            list.splice(index, 1);
          }
        }
        break;
        case "checkedAudio":
        if(event.target.checked===true){
          list.push("audio");
        }else {
          const index = list.indexOf("audio");
          if (index > -1) {
            list.splice(index, 1);
          }
        }
        break;
        case "checkedImage":
        if(event.target.checked===true){
          list.push("image");
        }else {
          const index = list.indexOf("image");
          if (index > -1) {
            list.splice(index, 1);
          }
        }
        break;
        case "checkedImage360":
        if(event.target.checked===true){
          list.push("image-360");
        }else {
          const index = list.indexOf("image-360");
          if (index > -1) {
            list.splice(index, 1);
          }
        }
        break;
        case "checkedVideoFlat":
        if(event.target.checked===true){
          list.push("video-flat");
        }else {
          const index = list.indexOf("video-flat");
          if (index > -1) {
            list.splice(index, 1);
          }
        }
        break;
        case "checkedVideo360":
        if(event.target.checked===true){
          list.push("video-360");
        }else {
          const index = list.indexOf("video-360");
          if (index > -1) {
            list.splice(index, 1);
          }
        }
        break;
        default:


      }

      return list;

    }

    getListSubtypeComplex = (event) =>{
      let list =   this.state.subtypeComplex;

      switch(event.target.name){
        case "checkedAllComplex":
        if(event.target.checked===true){
          return ["all"];
        }else return ["storymap"];

        case "checkedStorymap":
        if(event.target.checked===true){
          list.push("storymap");
        }else {
          const index = list.indexOf("storymap");
          if (index > -1) {
            list.splice(index, 1);
          }
        }

        break;
        case "checkedWebdoc":
        if(event.target.checked===true){
          list.push("webdoc");
        }else {
          const index = list.indexOf("webdoc");
          if (index > -1) {
            list.splice(index, 1);
          }
        }

        break;
        case "checkedExibition":
        if(event.target.checked===true){
          list.push("exhibition");
        }else {
          const index = list.indexOf("exhibition");
          if (index > -1) {
            list.splice(index, 1);
          }
        }

        break;
        default:

      }
      return list;
    }


    handleChangeAtomic = (event) => {

      let listSubtype= this.getListSubtypeAtomic(event);

      this.setState({[event.target.name]: event.target.checked ,
        subtype:listSubtype,
        subtypeAtomic:listSubtype},
        this.props.data({
          typesearch:this.state.typesearch,
          target:this.state.target,
          subtype:listSubtype,
          title:this.state.title,
          description:this.state.description,
          license:this.state.license,
          creator:this.state.creator,
          language:this.state.language,
          date:this.state.date
        }));

        if(this.state.checkedAllAtomic===true){
          this.setState({checkedAllAtomic:false});
        }
      }

      handleChangeAtomicAll = (event) => {
        let listSubtype= this.getListSubtypeAtomic(event);

        this.setState({[event.target.name]: event.target.checked,
          subtype:listSubtype,
          subtypeAtomic:listSubtype},
          this.props.data({
            typesearch:this.state.typesearch,
            target:this.state.target,
            subtype:listSubtype,
            title:this.state.title,
            description:this.state.description,
            license:this.state.license,
            creator:this.state.creator,
            language:this.state.language,
            date:this.state.date
          }));

          if(event.target.checked===true){
            this.setState({
              checked3dobj:true,
              checkedAudio:true,
              checkedImage:true,
              checkedImage360:true,
              checkedVideoFlat:true,
              checkedVideo360:true,
            })
          }
          else {
            this.setState({
              checkedAllAtomic:false,
              checked3dobj:false,
              checkedAudio:true,
              checkedImage:false,
              checkedImage360:false,
              checkedVideoFlat:false,
              checkedVideo360:false,
            })
          }
        }

        handleChangeTarget= (event) => {
          let listSubtype=[];
          if(event.target.value==="atomic"){
            listSubtype=this.state.subtypeAtomic;
          }
          else if(event.target.value==="complex"){
            listSubtype=this.state.subtypeComplex;
          }
          else listSubtype=[];

          this.setState({
            targetValue:event.target.value,
            license: event.target.value==="atomic" ? this.state.license : "",
            language: event.target.value==="crawled" ? "" : this.state.language,
            title: event.target.value==="crawled" ? "" : this.state.title,
            description:"" ,
            checkedDescription: event.target.value==="crawled" ? true : false,
            subtype:listSubtype,
            target:event.target.value},
            this.props.data({
              typesearch:this.state.typesearch,
              target:event.target.value,
              subtype:listSubtype,
              title:event.target.value==="crawled" ? "" : this.state.title,
              description:this.state.description,
              license:event.target.value==="atomic" ? this.state.license : "",
              creator:this.state.creator,
              language:event.target.value==="crawled" ? "" : this.state.language,
              date:this.state.date
            }));
          }

          handleChangeComplex= (event) => {
            let listSubtype= this.getListSubtypeComplex(event);
            console.log(listSubtype);

            this.setState({[event.target.name]: event.target.checked,
              subtype:listSubtype,
              subtypeComplex:listSubtype},
              this.props.data({
                typesearch:this.state.typesearch,
                target:this.state.target,
                subtype:listSubtype,
                title:this.state.title,
                description:this.state.description,
                license:this.state.license,
                creator:this.state.creator,
                language:this.state.language,
                date:this.state.date
              }));

              if(this.state.checkedAllComplex===true){
                this.setState({checkedAllComplex:false});
              }
            }

            handleChangeComplexAll= (event) => {
              let listSubtype= this.getListSubtypeComplex(event);
              console.log(listSubtype);

              this.setState({[event.target.name]: event.target.checked ,
                subtype:listSubtype,
                subtypeComplex:listSubtype},
                this.props.data({
                  typesearch:this.state.typesearch,
                  target:this.state.target,
                  subtype:listSubtype,
                  title:this.state.title,
                  description:this.state.description,
                  license:this.state.license,
                  creator:this.state.creator,
                  language:this.state.language,
                  date:this.state.date
                }));

                if(event.target.checked===true){
                  this.setState({
                    checkedStorymap:true,
                    checkedWebdoc:true,
                    checkedExibition:true,
                  })
                }
                else {
                  this.setState({
                    checkedStorymap:true,
                    checkedWebdoc:false,
                    checkedExibition:false,
                    checkedAllComplex:false,
                  })
                }
              }

              handleChangeRight = (e)=>{

                // console.log("EVENTO :"+e.target.value);
                // selectedRight:getRights("CC0").value,

                this.setState({selectedRight:e.target.value,
                  license:e.target.value},
                  this.props.data({
                    typesearch:this.state.typesearch,
                    target:this.state.target,
                    subtype:this.state.target ==="atomic" ? this.state.subtypeAtomic : this.state.target ==="complex" ? this.state.subtypeComplex : [],
                    title:this.state.title,
                    description:this.state.description,
                    license:e.target.value,
                    creator:this.state.creator,
                    language:this.state.language,
                    date:this.state.date
                  }));

                }

                handlecheckedDescription = (event) => {
                  if(this.state.target.toUpperCase()!=="CRAWLED")
                  this.setState({[event.target.name]: event.target.checked,


                    // description: event.target.description,

                    // searcherrorDesc: (event.target.value.length > 0 && event.target.value.trim().length!==0 ? false : true)

                  })

                  if(event.target.checked===true){
                    //  this.setState({description:this.state.selectedRight}); NON CAMBIA
                  }else{
                    this.setState({description:""});

                  }

                }

                handleChangeDescription = (event) => {
                  this.setState({description: event.target.value,
                  // searcherrorDesc: (event.target.value.length > 0 && event.target.value.trim().length!==0 ? false : true)
                 },
                    this.props.data({
                      typesearch:this.state.typesearch,
                      target:this.state.target,
                      subtype:this.state.target ==="atomic" ? this.state.subtypeAtomic : this.state.target ==="complex" ? this.state.subtypeComplex : [],
                      title:this.state.title,
                      description:event.target.value,
                      license:this.state.license,
                      creator:this.state.creator,
                      language:this.state.language,
                      date:this.state.date
                    }));
                  }

                  handlecheckedLicense = (event) => {
                    this.setState({[event.target.name]: event.target.checked });
                    if(event.target.checked===true){
                      this.setState({license:this.state.selectedRight});
                    }else{
                      this.setState({license:""});

                    }

                  }



                  handlecheckedLanguage = (event) => {
                    this.setState({[event.target.name]: event.target.checked })

                  }



                  handlecheckedCreator = (event) => {
                    this.setState({[event.target.name]: event.target.checked })

                  }

                  handleChangeCreator = (event) => {
                    this.setState({creator: event.target.value },
                      this.props.data({
                        typesearch:this.state.typesearch,
                        target:this.state.target,
                        subtype:this.state.target ==="atomic" ? this.state.subtypeAtomic : this.state.target ==="complex" ? this.state.subtypeComplex : [],
                        title:this.state.title,
                        description:this.state.description,
                        license:this.state.license,
                        creator:event.target.value,
                        language:this.state.language,
                        date:this.state.date
                      }));
                    }

                    handlecheckedCreated = (event) => {
                      this.setState({[event.target.name]: event.target.checked })

                    }

                    handleChangeCreated = (event) => {
                      this.setState({date: event.target.value },

                        this.props.data({
                          typesearch:this.state.typesearch,
                          target:this.state.target,
                          subtype:this.state.target ==="atomic" ? this.state.subtypeAtomic : this.state.target ==="complex" ? this.state.subtypeComplex : [],
                          title:this.state.title,
                          description:this.state.description,
                          license:this.state.license,
                          creator:this.state.creator,
                          language:this.state.language,
                          date:event.target.value
                        }));
                      }
                      // checkSpace =() => {
                      //   $('body').on('keydown', '#title', function(e) {
                      //     console.log(this.value);
                      //     if (e.which === 32 &&  e.target.selectionStart === 0) {
                      //       return false;
                      //     }

                      // && (event.which === 32 &&  event.target.selectionStart === 0)

                      //   });
                      // }

                      // isWhitespaceNotEmpty = (text) => {
                      //   return text.length > 0 && !/[^\s]/.test(text);
                      // && event.target.value.trim().length === 0
                      // }

                      handleTitleField= (event) => {
                        this.setState({ title: event.target.value, searcherror: (event.target.value.length > 0 && event.target.value.trim().length!==0 ? false : true)},


                        this.props.data({
                          typesearch:this.state.typesearch,
                          target:this.state.target,
                          subtype:this.state.target ==="atomic" ? this.state.subtypeAtomic : this.state.target ==="complex" ? this.state.subtypeComplex : [],
                          title:event.target.value,
                          description:this.state.description,
                          license:this.state.license,
                          creator:this.state.creator,
                          language:this.state.language,
                          date:this.state.date
                        }));
                      }

                      render() {
                        let jsonlang = this.props.appstate.App.language;
                        return (
                          <>

                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            >
                              <Grid item xs={12}>
                                <FormControl component="fieldset">
                                  <Typography variant="overline" display="block" color="primary">
                                    TARGET
                                  </Typography>
                                  <RadioGroup
                                    row
                                    aria-label="TARGET"
                                    name="targetName"
                                    value={this.state.targetValue}
                                    onChange={this.handleChangeTarget}
                                    >
                                      <FormControlLabel value="atomic"
                                        control={<Radio color="primary"/>} label="ATOMIC" />
                                        <FormControlLabel value="complex"
                                          control={<Radio color="primary"/>} label="COMPLEX" />
                                          <FormControlLabel value="crawled"
                                            control={<Radio color="primary"/>} label="CRAWLED" />
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                    </Grid>

                                    <Divider light={true}/>
                                    <Grid
                                      container
                                      direction="row"
                                      //    style={{marginTop:5}}
                                      >
                                        <Typography variant="overline" display="block" color="primary">
                                          SUBTYPE
                                        </Typography>
                                        <Grid item xs={1}></Grid>

                                        <Grid item xs={11}>
                                          <FormGroup row>
                                            <FormControlLabel
                                              disabled={this.state.targetValue==="atomic" ?false :true}
                                              control={<Checkbox color="primary" checked={this.state.checkedAudio} onChange={this.handleChangeAtomic} name="checkedAudio"/>}
                                              label="AUDIO"
                                            />
                                            <FormControlLabel
                                              disabled={this.state.targetValue==="atomic" ?false :true}
                                              control={<Checkbox color="primary" checked={this.state.checkedImage} onChange={this.handleChangeAtomic} name="checkedImage"/>}
                                              label="IMAGE"
                                            />
                                            <FormControlLabel
                                              disabled={this.state.targetValue==="atomic" ?false :true}
                                              control={<Checkbox color="primary" checked={this.state.checkedImage360} onChange={this.handleChangeAtomic} name="checkedImage360"/>}
                                              label="IMAGE 360"
                                            />
                                            <FormControlLabel
                                              disabled={this.state.targetValue==="atomic" ?false :true}
                                              control={<Checkbox color="primary" checked={this.state.checkedVideoFlat} onChange={this.handleChangeAtomic} name="checkedVideoFlat"/>}
                                              label="VIDEO FLAT"
                                            />
                                            <FormControlLabel
                                              disabled={this.state.targetValue==="atomic" ?false :true}
                                              control={<Checkbox color="primary" checked={this.state.checkedVideo360} onChange={this.handleChangeAtomic} name="checkedVideo360"/>}
                                              label="VIDEO 360"
                                            />
                                            <FormControlLabel
                                              disabled={this.state.targetValue==="atomic" ?false :true}
                                              control={<Checkbox color="primary" checked={this.state.checked3dobj} onChange={this.handleChangeAtomic} name="checked3dobj"/>}
                                              label="3D OBJECT"
                                            />
                                            {/* <FormControlLabel
                                              disabled={this.state.targetValue==="atomic" ?false :true}
                                              control={<Checkbox color="primary" checked={this.state.checkedAllAtomic} onChange={this.handleChangeAtomicAll} name="checkedAllAtomic"/>}
                                              label="ALL"
                                            /> */}
                                          </FormGroup>
                                          <Grid item xs={1}></Grid>
                                          <Grid item xs={11}>
                                            <FormGroup row>
                                              <FormControlLabel
                                                disabled={this.state.targetValue==="complex" ?false :true}
                                                control={<Checkbox color="primary" checked={this.state.checkedStorymap} onChange={this.handleChangeComplex} name="checkedStorymap"/>}
                                                label="STORYMAP"
                                              />
                                              <FormControlLabel
                                                disabled={this.state.targetValue==="complex" ?false :true}
                                                control={<Checkbox color="primary" checked={this.state.checkedWebdoc} onChange={this.handleChangeComplex} name="checkedWebdoc"/>}
                                                label="WEBDOC"
                                              />
                                              <FormControlLabel
                                                disabled={this.state.targetValue==="complex" ?false :true}
                                                control={<Checkbox color="primary" checked={this.state.checkedExibition} onChange={this.handleChangeComplex} name="checkedExibition"/>}
                                                label="EXHIBITION"
                                              />
                                              {/* <FormControlLabel
                                                disabled={this.state.targetValue==="complex" ?false :true}
                                                control={<Checkbox color="primary" checked={this.state.checkedAllComplex} onChange={this.handleChangeComplexAll} name="checkedAllComplex"/>}
                                                label="ALL"
                                              /> */}
                                            </FormGroup>

                                          </Grid>
                                        </Grid>
                                      </Grid>


                                      <Divider light={true}/>


                                      <Grid
                                        container
                                        direction="row"
                                        >

                                          <Typography variant="overline" display="block" color="primary">
                                            MATCH
                                          </Typography>
                                          <Grid item xs={12}></Grid>
                                          <Grid item xs={12} sm={12} md={2}>

                                            {this.state.targetValue==="crawled"  ?
                                            <Typography
                                              style={{margin:"15px 0px 0px 30px", color:"#858585"}}
                                              display="body1"
                                              >
                                                TITLE
                                                <span
                                                  style={{
                                                    fontVariant:"super",
                                                    color:"#858585"
                                                  }}>*</span>
                                                </Typography>
                                                :
                                                <Typography
                                                  style={{margin:"15px 0px 0px 30px"}}
                                                  display="body1"
                                                  color="inherit"
                                                  >
                                                    TITLE
                                                    <span
                                                      style={{
                                                        fontVariant:"super",
                                                        color:"#858585"
                                                      }}>*</span>
                                                    </Typography>
                                                  }
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                  <TextField
                                                    error={this.state.searcherror}
                                                    onChange={this.handleTitleField}
                                                    name="title"
                                                    id="title"
                                                    // required={true}
                                                    disabled={this.state.targetValue==="crawled" ? true : false}
                                                    InputProps={{ disableUnderline: this.state.targetValue==="crawled" ? true : false }}
                                                    type="text"
                                                    value={this.state.title}
                                                    style={{width:290}}
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={2}>

                                                  <FormControlLabel
                                                    control={<Checkbox
                                                      color={this.state.target.toUpperCase()==="CRAWLED" ? "disabled" : "primary"} checked={this.state.checkedDescription} onChange={this.handlecheckedDescription} name="checkedDescription"
                                                    />}
                                                    label="DESCRIPTION"
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                  <TextField
                                                    error={this.state.target.toLowerCase()==="crawled" && this.state.description.length=== 0 &&  this.state.description.trim().length===0 ? true : false}
                                                    name="description"
                                                    onChange={this.handleChangeDescription}
                                                    value={this.state.description}
                                                    disabled={this.state.checkedDescription===false ? true : false}
                                                    id="description"
                                                    style={{width:290}}
                                                  />

                                                  {/* <TextField
                                                    error={this.state.searcherror}
                                                    onChange={this.handleTitleField}
                                                    name="title"
                                                    id="title"
                                                    // required={true}
                                                    disabled={this.state.targetValue==="crawled" ? true : false}
                                                    InputProps={{ disableUnderline: this.state.targetValue==="crawled" ? true : false }}
                                                    type="text"
                                                    value={this.state.title}
                                                    style={{width:290}}
                                                  /> */}

                                                </Grid>


                                                <Grid item xs={2} sm={12} md={2}>
                                                  <FormControlLabel
                                                    disabled={this.state.targetValue==="crawled" || this.state.targetValue==="complex" ? true : false}
                                                    control={<Checkbox color="primary" checked={this.state.checkedLicense} onChange={this.handlecheckedLicense} name="checkedLicense"/>}
                                                    label="LICENSE"
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                  <Select
                                                    required
                                                    id="id"
                                                    displayEmpty
                                                    disabled={this.state.checkedLicense===false || this.state.targetValue==="crawled" || this.state.targetValue==="complex" ? true :false}
                                                    style={{width:290}}
                                                    value={this.state.selectedRight}
                                                    onChange={this.handleChangeRight}
                                                    >
                                                      <MenuItem disabled value="">
                                                        <em>{jsonlang.selerig.toUpperCase()}</em>
                                                      </MenuItem>
                                                      {this.state.rights.map((right, i) =>
                                                        <MenuItem  key={i} className="MuiInputBase-input-custom" value={right.value}>{right.value}</MenuItem>
                                                      )}
                                                    </Select>
                                                  </Grid>

                                                  <Grid item xs={12} sm={12} md={2}>
                                                    <FormControlLabel
                                                      disabled={this.state.targetValue==="crawled" ? true : false}
                                                      control={<Checkbox color="primary" checked={this.state.checkedLanguage} onChange={this.handlecheckedLanguage} name="checkedLanguage"/>}
                                                      label="LANGUAGE"
                                                    />
                                                  </Grid>
                                                  <Grid item xs={12} sm={12} md={4}>
                                                    <Select
                                                      multiple
                                                      disabled={this.state.checkedLanguage===false || this.state.targetValue==="crawled" ? true :false}
                                                      labelId="languages_choose"
                                                      id="languages_select"
                                                      value={this.state.selectLanguage}
                                                      onChange={this.onChangeselectLanguage}
                                                      displayEmpty
                                                      style={{width:290}}
                                                      renderValue={(selected) => selected.join(', ')}
                                                      input={<Input />}
                                                      >

                                                        {languages.map((item, index) =>
                                                          <MenuItem key={index} value={item.name} code={item.code} selected>
                                                            <ListItemIcon>
                                                              <img alt="flag" name={item.code} style={{ width: "22px", height: "22px" }} src={"/images/languages/" + item.code + ".svg"}></img>
                                                            </ListItemIcon>
                                                            <Typography variant="inherit">{item.name}</Typography>
                                                          </MenuItem>
                                                        )}
                                                      </Select>
                                                    </Grid>


                                                    <Grid item xs={12} sm={12} md={2}>
                                                      <FormControlLabel
                                                        control={<Checkbox color="primary" checked={this.state.checkedCreator} onChange={this.handlecheckedCreator} name="checkedCreator"/>}
                                                        label="CREATOR"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4}>
                                                      <TextField
                                                        onChange={this.handleChangeCreator}
                                                        disabled={this.state.checkedCreator===false ? true :false}
                                                        id="text"
                                                        style={{width:290}}
                                                      />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={2}>
                                                      <FormControlLabel
                                                        control={<Checkbox color="primary" checked={this.state.checkedCreated} onChange={this.handlecheckedCreated} name="checkedCreated"/>}
                                                        label="CREATED AT"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4}>
                                                      <TextField
                                                        onChange={this.handleChangeCreated}
                                                        disabled={this.state.checkedCreated===false ? true :false}
                                                        style={{width:290}}
                                                        id="date"
                                                        type="date"
                                                        defaultValue=" "
                                                        value={this.state.date}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                      />
                                                    </Grid>

                                                    <Grid item xs={12} style={{marginBottom:24}}></Grid>

                                                  </Grid>
                                                </>
                                              )
                                            }
                                          }
                                          export default connect(mapStateToProps)(ResearchMatch);
