import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getService,
  postService
} from "../../services/services";
import * as services from "../../services/services.json";
// import ReplyIcon from '@material-ui/icons/Reply';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
// import Collapse from '@material-ui/core/Collapse';
import MuiAlert from '@material-ui/lab/Alert';
// import TodayIcon from '@material-ui/icons/Today';
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import InfoIcon from '@material-ui/icons/Info';
//import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
// import { getRights} from "../Utils/utility";

import {
  // Avatar,
  // Table,
  Badge,
  CircularProgress,
  ClickAwayListener,
  // TableCell ,
  // TableContainer,
  // TableHead,
  // TableRow,
  // Paper,
  Button,
  IconButton,
  // Box,
  // ButtonGroup,
  // Input,
  // InputBase,
  // Divider,
  // Tabs,
  // Tab,
  AppBar,
  // InputAdornment,
  Toolbar,
  // Switch,
  Tooltip,
  // TextField,
  // Fab,
  Card,
  CardHeader,
  CardMedia,
  Typography,
  // Avatar,
  CardContent,
  CardActions,
  // Checkbox,
  Grid,
  Popover,
  // Popper,
  // Portal,
  Snackbar,
  Link,
  // Menu,
  // MenuItem
} from "@material-ui/core";

import './DetailSemanticSearch.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class DetailSemanticSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewNoItem: false,
      itemDetail: null,
      severity: "success",
      items: [],
      idSearch: "",
      order: "reup",
      search_name: "",
      itemInfo: "",
      openDropdown: true,
      openMenuDropdown: false,
      URIOpenDropdownInfoCard: "",
      ModifiedOpenDropdownInfoCard: "",
      openMenuDropdownInfoCard: false,

    }

  }

  dropdownList = () => {

    return (

      <div className="dropdown">

        <Typography variant="subtitle1">
          <div style={{ marginTop: -10 }}><h5>SEARCH PARAMETERS</h5></div>
        </Typography>
        {this.state.itemDetail.title !== "null123!" ?
          <Typography variant="subtitle1">
            title: {this.state.itemDetail.title}
          </Typography> : <></>}

        {this.state.itemDetail.description !== "null123!" ?
          <Typography variant="subtitle1">
            description: {this.state.itemDetail.description}
          </Typography> : <></>}

        {this.state.itemDetail.license !== "null123!" ?
          <Typography variant="subtitle1">
            license: {this.state.itemDetail.license}
          </Typography> : <></>}

        {this.state.itemDetail.creator !== "null123!" ?
          <Typography variant="subtitle1">
            creator: {this.state.itemDetail.creator}
          </Typography> : <></>}

        {this.state.itemDetail.language !== "null123!" ?
          <Typography variant="subtitle1">
            language: {this.state.itemDetail.language.join()}
          </Typography> : <></>}

        {this.state.itemDetail.date !== "null123!" ?
          <Typography variant="subtitle1">
            date: {this.state.itemDetail.date}
          </Typography> : <></>}

        {this.state.itemDetail.subtype.length > 0 ?
          <Typography variant="subtitle1">
            subtype: {this.state.itemDetail.subtype.join()}
          </Typography> : <></>}

      </div>

    )


  }


  menuDropdown = (event) => {
    console.log("menuDropdown");
    var thisRef = this;
    //thisRef.state.anchorEl = event.currentTarget;
    thisRef.setState({ openMenuDropdown: true, anchorEl: event.currentTarget });
  }

  handleClickAway = () => {
    // this.state.anchorEl= null;
    this.setState({ openMenuDropdown: false, anchorEl: null })
  }

  handleCloseDropdown = () => {
    // this.state.anchorEl= null;
    this.setState({ openMenuDropdown: false, anchorEl: null })
  }

  truncate = (str, max) => {
    return str.length > max ? str.substr(0, max - 1) + '…' : str;
  }


  menuDropdownInfoCard = (event, item) => {
    console.log("menuDropdown " + JSON.stringify(item));
    var thisRef = this;
    //thisRef.state.anchorEl = event.currentTarget;
    thisRef.setState({
      openMenuDropdownInfoCard: true, anchorElInfoCard: event.currentTarget,
      URIOpenDropdownInfoCard: item.URI, ModifiedOpenDropdownInfoCard: item.modified
    });
  }

  handleClickAwayInfoCard = () => {
    // this.state.anchorEl= null;
    this.setState({ openMenuDropdownInfoCard: false, anchorElInfoCard: null, URIOpenDropdownInfoCard: "", ModifiedOpenDropdownInfoCard: "" })
  }

  handleCloseDropdownInfoCard = () => {
    // this.state.anchorEl= null;
    this.setState({ openMenuDropdownInfoCard: false, anchorElInfoCard: null, URIOpenDropdownInfoCard: "", ModifiedOpenDropdownInfoCard: "" })
  }


  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');

    // console.log("search_name "+myParam);
    this.setState({ idSearch: myParam });

    this.getDetailSemanticSearch(myParam);
  }


  getDetailSemanticSearch = (myParam) => {

    let self = this;
    let serviceurl = process.env.REACT_APP_DOMAIN + services.semanticsearch_GET;
    serviceurl = serviceurl.replace("{APPNAME}", this.props.appstate.App.name);
    serviceurl = serviceurl.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    serviceurl = serviceurl.replace("{ID}", myParam);
    getService(serviceurl, function (err, result) {
      if (err) {

        return;
      }
      if (result.status === 200) {
        // console.log(result.response);
        self.setState({ itemDetail: result.response.db });
        self.loadItem(result.response.search);
      }

      return;
    });
  }



  loadItem = (items) => {

    if (items.length === 0) {
      this.setState({ viewNoItem: true });
      // console.log(this.state.viewNoItem);
      return;
    }
    this.setState({ items: items });
  }

  getDownloadCoverUrl = (item) => {
    if (this.state.itemDetail.target.toLowerCase() === "atomic") {
      if (item.mimetype.includes("image"))
        return item.url;
      else
        return item.coverUrl;
    }

    else if (this.state.itemDetail.target.toLowerCase() === "crawled") {
      if (item.source === "youtube")
        return item.coverUrl;
      else if (item.source === "websites")
        return item.coverUrl;

    }
  }


  getCover = (item) => {
    if (this.state.itemDetail.target.toLowerCase() === "atomic") {
      if (item.mimetype.includes("image"))
        return item.url;

      // else if(item.mimetype === "audio/mpeg")
      // return "/images/icons/sound-bars.png";
      //
      else if (item.mimetype === "audio/mpeg" && item.coverUrl !== "")
        return item.coverUrl;
      else if (item.mimetype === "audio/mpeg" && item.coverUrl === "")
        return "/images/icons/sound-bars.png";
      else if (item.mimetype.includes("video") && item.coverUrl !== "")
        return item.coverUrl;
      else if (item.mimetype.includes("video") && item.coverUrl === "")
        return "/images/icons/video.png";
      else return "/images/icons/ext_res.png";
    }

    else if (this.state.itemDetail.target.toLowerCase() === "complex") {
      return "/images/icons/all-complex.png";
    }

    else if (this.state.itemDetail.target.toLowerCase() === "crawled") {
      if (item.source === "twitter")
        return "/images/icons/twitter-48.png";
      else if (item.source === "youtube")
        return item.coverUrl;
      else if (item.source === "websites")
        return item.coverUrl;
      else
        return "/images/icons/ext_res.png";
    }
    else return "/images/icons/ext_res.png";

  }

  getClassName = (item) => {
    if (this.state.itemDetail.target.toLowerCase() === "atomic") {
      if (item.mimetype.includes("image"))
        return "imagemediasemantic";
      else if (item.mimetype === "audio/mpeg" && item.coverUrl !== "")
        return "imagemediasemantic";
      else if (item.mimetype === "audio/mpeg" && item.coverUrl === "")
        return "imagemediasemantic contain";
      else if (item.mimetype.includes("video") && item.coverUrl !== "")
        return "imagemediasemantic";
      else if (item.mimetype.includes("video") && item.coverUrl === "")
        return "imagemediasemantic contain";
      else return "imagemediasemantic contain";
    }
    else if (this.state.itemDetail.target.toLowerCase() === "complex") {
      return "imagemediasemantic contain";
    }
    else if (this.state.itemDetail.target.toLowerCase() === "crawled") {
      if (item.source === "twitter")
        return "imagemediasemantic contain";
      else if (item.source === "youtube")
        return "imagemediasemantic";
      else if (item.source === "websites")
        return "imagemediasemantic";
      else
        return "imagemediasemantic contain";

    }
    else return "imagemediasemantic contain";

  }

  getCrawledIcons = (item) => {

    if (item.source === "twitter")
      return (<img style={{ width: "32px", height: "32px" }} alt="twitter" src="/images/icons/twitter-48.png" />)
    else if (item.source === "youtube")
      return (<img alt="youtube" style={{ width: "32px", height: "32px" }} src="/images/icons/youtube-48.png" />)
    else return (
      <img alt="generic" style={{ width: "32px", height: "32px" }} src="/images/icons/ext_res.png" />)
  }

  getRightsIcons = (item) => {

    if (item.rights.includes("creativecommons.org/publicdomain/zero/1.0/"))
      return (<img className="rights-img-RM" alt="CC0" src="images/rights/cc-zero.png" />)

    else if (item.rights.includes("creativecommons.org/licenses/by/4.0/"))
      return (<img className="rights-img-RM" alt="CC-BY-4.0" src="images/rights/by.png" />)

    else if (item.rights.includes("creativecommons.org/licenses/by-sa/4.0/"))
      return (<img className="rights-img-RM" alt="CC-BY-SA" src="images/rights/by.png" />)

    else if (item.rights.includes("creativecommons.org/licenses/by-nd/4.0/"))
      return (<img className="rights-img-RM" alt="CC-BY-ND" src="images/rights/by-nd.png" />)

    else if (item.rights.includes("creativecommons.org/licenses/by-nc/4.0/"))
      return (<img className="rights-img-RM" alt="CC-BY-NC" src="images/rights/by-nc.png" />)

    else if (item.rights.includes("creativecommons.org/licenses/by-nc-sa/4.0/"))
      return (<img className="rights-img-RM" alt="CC-BY-NC-SA" src="images/rights/by-nc-sa.png" />)

    else if (item.rights.includes("creativecommons.org/licenses/by-nc-nd/4.0/"))
      return (<img className="rights-img-RM" alt="CC-BY-NC-ND" src="images/rights/by-nc-nd.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/InC/1.0/"))
      return (<img className="rights-img-RM" alt="IN COPYRIGHT" src="images/rights/InC.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/InC-OW-EU/1.0/"))
      return (<img className="rights-img-RM" alt="IN COPYRIGHT - EU ORPHAN WORK" src="images/rights/InC-OW-EU.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/InC-EDU/1.0/"))
      return (<img className="rights-img-RM" alt="IN COPYRIGHT - EDUCATIONAL USE PERMITTED" src="images/rights/InC-EDU.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/InC-NC/1.0/"))
      return (<img className="rights-img-RM" alt="IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED" src="images/rights/InC-NC.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/InC-RUU/1.0/"))
      return (<img className="rights-img-RM" alt="IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE" src="images/rights/InC-RUU.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/CNE/1.0/"))
      return (<img className="rights-img-RM" alt="COPYRIGHT NOT EVALUATED (CNE)" src="images/rights/CNE.png" />)

    else if (item.rights.includes("creativecommons.org/publicdomain/mark/1.0/"))
      return (<img className="rights-img-RM" alt="PUBLIC DOMAIN MARC - PDM" src="images/rights/Public_Domain_Mark.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/NoC-NC/1.0/"))
      return (<img className="rights-img-RM" alt="NO COPYRIGHT – NON COMMERCIAL RE-USE ONLY (NoC-NC)" src="images/rights/NoC-NC.png" />)

    else if (item.rights.includes("rightsstatements.org/vocab/NoC-OKLR/1.0/"))
      return (<img className="rights-img-RM" alt="NO COPYRIGHT – OTHER KNOWN LEGAL RESTRICTION (NoC-OKLR)" src="images/rights/NoC-OKLR.png" />)

    else return (
      <img className="rights-img-RM" alt="COPYRIGHT NOT EVALUATED (CNE)" src="images/rights/CNE.png" />)
  }

  downloadCard = (item) => {
    let self = this;
    console.log('stai facendo download di: ' + item.userName);

    let titleCopy = {};

    let descCopy = {};
    if (item.title !== "")
      titleCopy[this.props.appstate.App.currentLanguage] = item.title;
    if (item.description !== "")
      descCopy[this.props.appstate.App.currentLanguage] = item.description;

    let newform = {
      appname: this.props.appstate.App.name,
      userId: this.props.appstate.App.user.userid,
      domain: this.props.appstate.App.user.domain,
      name: item.title,
      description: descCopy,
      dest: "resource",
      type: "webcloud",
      url: item.url,
      isExtRes: true,
      title: titleCopy,
      right: "IN COPYRIGHT",
      linkright: "",
      degree: "2d",
      mediaLanguage: this.props.acamar.configuration.Languages.default,
      creator: item.userName,
      dcRightsHolder: "",
      medialan: this.props.acamar.configuration.Languages.default,
      mediares: "first",
      uriCoverExternalRes: this.getDownloadCoverUrl(item),
      publicStatusExtRes: ""
    }
    this.setState({
      form: newform
    });
    console.log(JSON.stringify(this.state.form));

    postService(process.env.REACT_APP_DOMAIN + services.resourcemanager_external_res_POST, newform,
      function (err, result) {

        if (err) {
          // console.log("errore");
          self.setState({ downloadInfo: true, severity: "error" });
          return;
        }

        if (result.status === 201) {
          // console.log("success");
          self.setState({ downloadInfo: true, severity: "success" });
        }

        return;
      })

  }

  render() {


    let jsonlang = this.props.appstate.App.language;
    return (

      <div>
        {this.state.itemDetail != null ?
          <div>

            <AppBar position="fixed" color="disabled" style={{ paddingTop: 30 }}>
              <Toolbar variant="dense" color="inherit">

                <Tooltip title={<h2>BACK</h2>} >
                  <IconButton edge="start" color="inherit" aria-label="back"
                    onClick={() => { this.props.history.push('/Semantic'); }}>
                    <ArrowBackIcon
                    />
                  </IconButton>
                </Tooltip>
                <div className="centerInfo">


                  <Badge color="primary" badgeContent={this.state.items.length} max={999}>
                    <ViewComfyIcon />
                  </Badge>


                  <Tooltip title={<h2>{this.state.itemDetail.target.toUpperCase().toString()}</h2>}>
                    <Typography color='default' className="ellips-soc">
                      {this.state.itemDetail.target.toUpperCase().toString()}
                    </Typography>
                  </Tooltip>
                  <Tooltip title={<h2>{new Date(this.state.itemDetail.createdAt).toUTCString().split(' ').slice(1).join(' ')}</h2>}>
                    <Typography color='default' className="ellips-soc">
                      {new Date(this.state.itemDetail.createdAt).toUTCString().split(' ').slice(1).join(' ')}
                    </Typography>
                  </Tooltip>
                </div>

                <ClickAwayListener onClickAway={this.handleClickAway}>
                  <div>
                    <Tooltip
                      // title={this.dropdownList()}
                      title={<h2>INFO</h2>}
                    >
                      <Button
                        color="primary"
                        // onClick={this.handleClickDropdown}
                        onClick={this.menuDropdown}
                      >
                        <InfoIcon />
                      </Button>

                    </Tooltip>
                    {this.state.openDropdown ? (
                      <>

                        {/* <-- used to display search info */}

                        <Popover
                          open={this.state.openMenuDropdown}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleCloseDropdown}

                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >

                          {this.dropdownList()}

                        </Popover>
                      </>
                    ) : null}
                  </div>
                </ClickAwayListener>

                {/*
                      <Checkbox
                      checked={this.state.order==="dateup" ? true : false}
                      onChange={this.orderList}
                      name="dateup"
                      color="primary"
                      icon={<ArrowDropUpIcon fontSize="large" />}
                      checkedIcon={<ArrowDropUpIcon fontSize="large"
                      className="btn-dotted"/>}
                    />

                    <Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
                    <TodayIcon />
                  </Tooltip>
                  <Checkbox
                  checked={this.state.order==="datedown" ? true : false}
                  onChange={this.orderList}
                  name="datedown"
                  color="primary"
                  icon={<ArrowDropDownIcon fontSize="large" />}
                  checkedIcon={<ArrowDropDownIcon fontSize="large"
                  className="btn-dotted"/>}
                /> */}



              </Toolbar>
            </AppBar>

            <AppBar position="fixed" color="default">
              <Typography
                variant='h6'
                justify='center'
                align='center'
              >
                {jsonlang.semanticsearch.toUpperCase()}
              </Typography>
            </AppBar>
            {this.state.items.length > 0 ?
              <div style={{ paddingTop: 100, paddingBottom: 40 }}>

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {this.state.items.map((item, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>

                      <Card key={item._id}>
                        <CardHeader
                          title={
                            <Tooltip title={<h2>{item.title}</h2>}>
                              <div>{item.title}</div>
                            </Tooltip>
                          }
                        />

                        <div
                          onClick={() => window.open(item.url, "_blank")}
                          style={{ cursor: "pointer" }}>

                          <CardMedia
                            image={
                              this.getCover(item)

                            }
                            title={item.title}
                            className={
                              this.getClassName(item)
                            }
                          />
                          <CardContent>
                            <Typography variant="body2" color="textSecondary" className="textDesc">
                              {item.description}
                            </Typography>
                          </CardContent>
                        </div>
                        <CardActions disableSpacing>
                          {/* {this.state.itemDetail.target.toLowerCase()!=="crawled" && item.creator!=="" ?
                            <Tooltip title={<h2>{item.creator}</h2>}>
                            <AssignmentIndIcon color="disabled" style={{marginRight: '10px'}}/>
                          </Tooltip>
                          :
                          <Tooltip title={<h2>unknown creator</h2>}>
                          <AssignmentIndIcon color="disabled" style={{marginRight: '10px'}}/>
                        </Tooltip>
                      } */}

                          {/* inizio info card */}
                          {/* <ClickAwayListener onClickAway={this.handleClickAwayInfoCard}> */}
                          <div>
                            <Tooltip
                              // title={this.dropdownList()}
                              title={<h2>INFO CARD</h2>}
                            >
                              <Button
                                color="primary"
                                id={item._id}
                                // onClick={this.handleClickDropdown}
                                onClick={(e) => this.menuDropdownInfoCard(e, item)}
                              >
                                <InfoIcon />
                              </Button>

                            </Tooltip>

                            {this.state.URIOpenDropdownInfoCard === item.URI &&
                              this.state.ModifiedOpenDropdownInfoCard === item.modified &&
                              <Popover
                                open={this.state.openMenuDropdownInfoCard}
                                anchorEl={this.state.anchorElInfoCard}
                                onClose={this.handleCloseDropdownInfoCard}

                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <div className="dropdownINFO">
                                  <Typography variant="subtitle1">
                                    <div style={{ marginTop: -10 }}><b>Card information:</b></div>
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    <b>title: </b>{this.truncate(item.title, 34)}
                                    {/* {item.title} */}
                                  </Typography>

                                  {item.description !== "" &&
                                    <Typography variant="subtitle1">
                                      <b>description: </b>{this.truncate(item.description, 34)}
                                      {/* {item.description} */}
                                    </Typography>
                                  }

                                  {item.modified !== "" &&
                                    <Typography variant="subtitle1">
                                      <b>modified: </b>{this.truncate(item.modified, 34)}
                                      {/* {item.modified} */}
                                    </Typography>
                                  }

                                  {item.creator !== "" &&
                                    <Typography variant="subtitle1">
                                      <b>creator: </b>{this.truncate(item.creator, 34)}
                                      {/* {item.creator} */}
                                    </Typography>
                                  }

                                  {item.mimetype !== "" &&
                                    <Typography variant="subtitle1">
                                      <b>mimetype: </b>{this.truncate(item.mimetype, 34)}
                                      {/* {item.creator} */}
                                    </Typography>
                                  }
                                </div>

                              </Popover>
                            }
                          </div>
                          {/* </ClickAwayListener> */}
                          {/* fine info card */}

                          <Tooltip title={<h2>modified date</h2>}>
                            <Typography variant="subtitle2" color="textSecondary">
                              {item.modified}
                            </Typography>
                          </Tooltip>
                          {/* download button */}
                          <IconButton
                            style={{ marginLeft: 'auto' }}
                            // TEST // onClick={()=> window.open(item.url, "_blank")}
                            onClick={() => this.downloadCard(item)}
                            aria-label={jsonlang.saveinres.toUpperCase()} //SAVE IN Resources Manager
                          >
                            <Tooltip title={<h2>{jsonlang.saveinres.toUpperCase()}</h2>}>
                              <SystemUpdateAltIcon
                                style={{ color: "black" }}
                              />
                            </Tooltip>
                          </IconButton>

                          <div style={{ marginLeft: 'auto' }}>
                            {this.state.itemDetail.target.toLowerCase() === "crawled" &&
                              this.getCrawledIcons(item)}
                          </div>
                          {this.state.itemDetail.target.toLowerCase() === "atomic" ?
                            <div style={{ padding: 5 }}>
                              <Tooltip title={item.rights}>
                                <Link
                                  color="inherit"
                                  role="link"
                                  aria-label={item.rights}
                                  href={item.rights}
                                  target="blank"
                                >
                                  {this.getRightsIcons(item)}
                                </Link>
                              </Tooltip>
                            </div>
                            : <></>}
                        </CardActions>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </div>
              : <div style={{
                textAlign: "center",
                padding: "150px"
              }} >{this.state.viewNoItem === false ? <CircularProgress /> : <></>}</div>}
          </div>
          : <div style={{
            textAlign: "center",
            padding: "150px"
          }} >{this.state.viewNoItem === false ? <CircularProgress /> : <></>}</div>}
        {this.state.viewNoItem === true ? <div style={{
          textAlign: "center"
        }} >{jsonlang.notresults}</div> : null}

        <>
          <Snackbar open={this.state.downloadInfo} autoHideDuration={4000} onClose={this.handleCloseSnack}>
            <Alert onClose={this.handleCloseSnack} severity={this.state.severity}>
              {this.state.severity === "success" ? <h2>{jsonlang.savedinres.toUpperCase()}</h2> : <>{jsonlang.resourcerror.toUpperCase()}</>}
            </Alert>
          </Snackbar>

        </>
      </div>

    )
  }
}

export default connect(mapStateToProps)(DetailSemanticSearch);
