import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getService } from "../services/services";
import * as services from "../services/services.json";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {
  Table,
  // TableBody,
  TableCell ,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  // IconButton,
  // Box,
  // ButtonGroup,
  // InputBase,
  // Divider,
  // Tabs,
  // Tab,
  AppBar,
  // InputAdornment,
  // Toolbar,
  // Switch,
  Tooltip,
  // TextField,
  Fab,
  // Card,
  // CardHeader,
  // CardMedia,
  Typography,
  // CardContent,
  // CardActions,
  // Checkbox,
  // Grid,
  // Link,
  // Menu,
  // MenuItem
} from "@material-ui/core";


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQrcode,faClosedCaptioning,faCopyright,faBan} from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@material-ui/icons/Add';


import DialogAddSocialMedia from './Dialog/DialogAddSocialMedia';

import DialogDelete from './Dialog/DialogDelete';
import './Home.css';




const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog:false,
      showDialogDelete:false,
      orderStatus:"desc",
      orderSource:"asc",
      orderName:"desc",
      orderDate:"asc",
      items: [],
      itemDelete:"",

    }

  }

  componentDidMount() {
    this.getAllSocialMedia();
  }


  getAllSocialMedia = () => {

    let self=this;
    let serviceurl = process.env.REACT_APP_DOMAIN + services.socialmedia_GET_ALL;
    serviceurl = serviceurl.replace("{APPNAME}", this.props.appstate.App.name);
    serviceurl = serviceurl.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    serviceurl = serviceurl.replace("{OWNER}", this.props.appstate.App.user.userid);
    getService(serviceurl, function (err, result) {
      if (err) {

        return;
      }
      if (result.status === 200) {
        if(result.response.length>0){
          self.setState({items:result.response});
        } else self.setState({items:[]});
        return;
      }

      return;
    });
  }


  addSocialMedia = () => {
    this.setState({showAddDialog:true});
  }

  closeAddSocialMedia = () => {
    this.setState({showAddDialog:false});
  }


  AllSocial = (item) => {
    // console.log(item);
      this.props.history.push('/AllSocial?id='+item.search_name);
  }
//barra filtri function
order=(event) =>{
  // console.log(event);
  switch (event.currentTarget.value) {
    case "source": // DA MODIFICARE PER SOURCE ANZICHE' SHORTNAME
    if (this.state.orderSource ==="asc"){
      let orderArray= this.itemsSortInverse("source");
      this.setState({items:orderArray,orderSource:"desc"});
    }else{
      let orderArray=this.itemsSort("source");
      this.setState({items:orderArray,orderSource:"asc"});
    }
    break;
    // case "name":
    // if (this.state.orderShortName ==="asc"){
    //   let orderArray= this.itemsSortInverse("name");
    //   this.setState({users:orderArray,orderShortName:"desc"});
    // }else{
    //   let orderArray=this.itemsSort("name");
    //   this.setState({users:orderArray,orderShortName:"asc"});
    // }
    // break;
    case "date":
    if (this.state.orderDate ==="asc"){
      let orderArray= this.itemsSortInverse("createdAt");
      this.setState({items:orderArray,orderDate:"desc"});
    }else{
      let orderArray=this.itemsSort("createdAt");
      this.setState({items:orderArray,orderDate:"asc"});
    }
    break;
    default:
    break;
  }

}

itemsSort =(itemToOrder) =>{
  let ascend = this.state.items.slice(); //copy array
  ascend.sort (function (a,b)
  {
    // console.log( a[itemToOrder].toString());
    // console.log( b[itemToOrder].toString());
    return a[itemToOrder].toString().localeCompare(b[itemToOrder].toString());
  }
);
return ascend;
}

itemsSortInverse = (itemToOrder) =>
{
  var descend = this.state.items.slice();
  descend.sort (function (a, b)
  {
    // console.log( a[itemToOrder].toString());
    // console.log( b[itemToOrder].toString());
    return b[itemToOrder].toString().localeCompare(a[itemToOrder].toString());
  }
);
return descend;
}

getStatus = (date) => {

  let now = Date.now();
  if((now-date)>5000)
  return "done";
  else return "running";

}


openDialogForDelete = (item) => {
  this.setState({showDialogDelete:true, itemDelete:item});
}

closeDialogForDelete = () => {
  this.setState({showDialogDelete:false, itemDelete:""});
}


render() {
  let jsonlang = this.props.appstate.App.language;
  return (
    <div style={{paddingTop: "30px"}}>

      <DialogAddSocialMedia open={this.state.showAddDialog}
        onClose={this.closeAddSocialMedia}
        refreshCall={this.getAllSocialMedia}/>

        <DialogDelete open={this.state.showDialogDelete}
          item={this.state.itemDelete}
          onClose={this.closeDialogForDelete}
          refreshCall={this.getAllSocialMedia}/>

          <AppBar  position="fixed" color="default">
            <Typography
              variant='h6'
              justify='center'
              align='center'
              >
                {jsonlang.socialmedia.toUpperCase()}
              </Typography>

            </AppBar>
            <div>
              {/* tabella con la lista delle chiamate */}


            </div>
            {/*cambiare stringa*/}
            <Tooltip title={<h2>{jsonlang.adda.toUpperCase()}</h2>}>

            <Fab
              style={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 20,
                left: 'auto',
                position: 'fixed'}}
                role="button"
                color="primary"
                size="large"
                aria-label="Add"
                onClick={this.addSocialMedia}
                >
                  <AddIcon
                  />
                </Fab>

              </Tooltip>
              <div>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="list search">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.name.toUpperCase()}</h2>}>
                          <Button value="source" onClick={this.order}>
                            SOURCE
                            <ImportExportIcon/>
                          </Button>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip title={<h2>SEARCH</h2>}>
                        <Typography variant="buttoEARCHn" display="block" gutterBottom>
                          SEARCH
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
                      <Button value="date" onClick={this.order}>
                        DATE
                        <ImportExportIcon/>
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={<h2>STATUS</h2>}>
                    <Typography variant="button" display="block" gutterBottom>
                      STATUS
                    </Typography>
                  </Tooltip>
                </TableCell>



                {this.props.appstate.App.user.role!=="superadmin"   ?
                <TableCell align="center">
                  <Tooltip title={<h2>ACTION</h2>}>
                  <Typography variant="button" display="block" gutterBottom>
                    ACTION
                  </Typography>
                </Tooltip>
              </TableCell>

              :<></>}


              <TableCell align="center">

                <Typography variant="button" display="block" gutterBottom>
                  {/* va lasciato vuoto per compensare la griglia sotto di action */}
                </Typography>

            </TableCell>

          </TableRow>
        </TableHead>
        {this.state.items.map((item,index) =>
          <TableRow key={index} tabIndex="1">

            <TableCell align="center">
              <div>
                <Tooltip title={<h2>{item.source.toUpperCase()}</h2>} >
                <Typography
                  className="ellips"
                  >
                    {/* {item.source} */}
                    {item.source.toUpperCase() ==="TWITTER" ?
                    <TwitterIcon
                      color="primary"
                    />
                    : item.source.toUpperCase() ==="YOUTUBE" ?
                    <YouTubeIcon
                      color="secondary"
                    />
                    : item.source.toUpperCase() ==="DIGITALMEETS" ?
                    <span alt="DIGITAL MEETS"
                      style={{
                        backgroundImage:`url("/images/icons/digital-meets-icon.jpg")`,
                        position: 'absolute',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center 40%',
                        height:"15px",
                        width:"85px",
                        margin: "-5px 0px 0px -40px"
                    }}
                    />

                    : item.source.toUpperCase() ==="AMNESTY" ?
                    <span alt="AMNESTY"
                      style={{
                        backgroundImage:`url("/images/icons/amnesty-icon.jpg")`,
                        position: 'absolute',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center 40%',
                        height:"24px",
                        width:"22px",
                        margin: "-10px 0px 0px -10px"
                      }}
                         />

                    : item.source.toUpperCase() ==="COURAGEOPPOSITION" ?
                    <span alt="COURAGE OPPOSITION"
                      style={{
                        backgroundImage:`url("/images/icons/cultural-opposition-icon.png")`,
                        position: 'absolute',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center 40%',
                        height:"24px",
                        width:"24px",
                        margin: "-10px 0px 0px -10px"
                      }}
                       />

                    : <></>
                  }
                  </Typography>
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="center">
              <Tooltip title={<h2>{item.keywords.toString()}</h2>} >
              <div>
                <Tooltip title={<h2>{item.keywords.toString()}</h2>}>
                {item.source.toUpperCase() ==="TWITTER" || item.source.toUpperCase() ==="YOUTUBE" ?
                  <Typography color='default' className="ellips">

                  {item.keywords.toString()}

                </Typography>
                : <></>}
              </Tooltip>
            </div>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <div>
            <Tooltip title={<h2>{new Date(item.createdAt).toUTCString().split(' ').slice(1).join(' ')}</h2>}>
            <Typography color='default' className="ellips">
              {new Date(item.createdAt).toUTCString().split(' ').slice(1).join(' ')}
            </Typography>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell align="center">

        <Tooltip title={<h2>status</h2>}>
        <Typography
          className="ellips"
          style={ this.getStatus(item.createdAt) === "running" ?{ color: "red" } :{  } }
          >
            {this.getStatus(item.createdAt) ==="running"? "RUNNING.." : "DONE"}
          </Typography>
        </Tooltip>
      </TableCell>

      {/* <TableCell align="center">
      <Tooltip title={<h2>VUOTO</h2>}>
      <Typography variant="button" display="block" gutterBottom>
      VUOTO
    </Typography>
  </Tooltip>
</TableCell> */}

<TableCell align="center">
  {/* <Tooltip title={<h2>{jsonlang.remove.toUpperCase()}</h2>}> */}
  <Tooltip title={<h2>SHOW</h2>}>
  <Button
    color="primary"
    value={item._id}
  //  disabled={this.getStatus(item.createdAt) ==="running"? true : false}
    onClick={()=>this.AllSocial(item)}
    >
      <VisibilityIcon/>
    </Button>

  </Tooltip>
  <Tooltip title={<h2>{jsonlang.remove.toUpperCase()}</h2>}>
  <Button
    color="primary"
    value={item._id}
    onClick={()=>this.openDialogForDelete(item)}
    >
      <DeleteForeverIcon/>
    </Button>

  </Tooltip>
</TableCell>

<TableCell align="center">
  <div>

    <Typography color='default' className="ellips">

    </Typography>

</div>
</TableCell>

</TableRow>
)}
</Table>
</TableContainer>
</div>





</div>
)
}
}

export default connect(mapStateToProps)(Home);
