import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  // Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  // FormControlLabel,
  // FormControl,
  // FormLabel,
  // Grid,
  // MenuItem,
  // Radio,
  // RadioGroup,
  Slide,
  // Tab,
  // Tabs,
  // TextField,
  Tooltip,
  Typography
} from "@material-ui/core";

// import PropTypes from 'prop-types';


import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
// import { APP } from '../../redux/actions/actions';
import {
  postService,
  // postServiceCERTH
} from "../../services/services";
import * as services from "../../services/services.json";

// import ResearchAverage from '../SemanticSearch/ResearchAverage';
// import ResearchCount from '../SemanticSearch/ResearchCount';
import ResearchMatch from '../SemanticSearch/ResearchMatch';
// import { getRights } from "../../Utils/utility"; //da utilizzare per MATCH/LICENSE


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};


//
// const TabPanel = (props) => {
//   const { children, value, index, ...other } = props;
//
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={index}
//       aria-labelledby={value}
//       {...other}
//       >
//         {value === index && (
//           <Box sx={{ p: 3 }}>
//             <Typography>{children}</Typography>
//           </Box>
//         )}
//       </div>
//     );
//   }
//
//   TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
//   };

// a11yProps = (index) =>{
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogAddSemanticSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog:false,
      call:{
        typesearch:"",
        target:"",
        subtype:"",
        title:"",
        description:"",
        license:"",
        creator:"",
        language:"",
        date:""
      },
      loading:false,
      tabvalue:0,

    }
  }
  componentDidMount() {

  }

  onclose = () => {

    this.props.onClose();

  }


  search = () => {

    let self = this;

    this.setState({loading:true});

    postService(
      process.env.REACT_APP_DOMAIN + services.semanticsearch_POST,
      {
        appname:self.props.appstate.App.name,
        domain:self.props.appstate.App.user.domain,
        owner: self.props.appstate.App.user.userid,
        typesearch:self.state.call.typesearch,
        target:self.state.call.target,
        subtype:self.state.call.subtype,
        title:self.state.call.title!=="" ? self.state.call.title : "null123!",
        description:self.state.call.description!=="" ? self.state.call.description : "null123!",
        license:self.state.call.license!=="" ? self.state.call.license : "null123!",
        creator:self.state.call.creator!=="" ? self.state.call.creator : "null123!",
        language:self.state.call.language!=="" ? self.state.call.language : "null123!",
        date:self.state.call.date!=="" ? self.state.call.date : "null123!"

      },
      function (err, result) {
        // console.log("result db" +result);
        // console.log("error db" +err);
        if (err) {
          return;
        }
        if (result.status === 204) {
          self.props.callback();
          return;
        }

        //self.props.history.go(0);
        //fine loading
        self.setState({loading:false});
        //chiusura dialog
        self.onclose();
        self.restCall({
          typesearch:"",
          target:"",
          subtype:"",
          title:"",
          description:"",
          license:"",
          creator:"",
          language:"",
          date:""
        });


        self.props.refreshCall();
        //  self.props.callback();
      }
    );

  }


  getDisabledSearch = () => {
    if(this.state.call.target.toUpperCase()!=="CRAWLED"){
      if(this.state.call.title==="")
      return true;
      else if (this.state.call.title.trim().length === 0)
      return true;
      else
      return false;
    }
    else if(this.state.call.target.toUpperCase()==="CRAWLED"){
      if(this.state.call.description.trim().length === 0)
      return true;    
    }
    else{

      return false;
    }
  }

  handleChangeTab=(event, newValue)=>{
    this.setState({tabvalue:newValue})
  }

  restCall=(obj)=>{
    //console.log(obj);
    this.setState({call:obj});
  }


  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        {this.state.loading ?
          <Dialog
            TransitionComponent={Transition}
            open={this.props.open}
            onClose={this.onclose}
            fullWidth
            maxWidth={'lg'}
            >
              <div style={{
                textAlign:"center",
                padding:"10px"
              }} >
              <CircularProgress
              />
            </div>
          </Dialog>

          :

          <Dialog
            // TransitionComponent={Transition}
            onBackdropClick="false"
            open={this.props.open}
            onClose={this.onclose}
            aria-labelledby="dialog-title"
            fullWidth
            maxWidth={'md'}
            >
              <DialogTitle id="dialog-add" style={{padding:"10px 24px"}}>
                <SearchIcon
                  aria-label="Search info"
                  color="primary"
                  style={{
                    // color: "#fff",
                    // backgroundColor: "#3f51b5",
                    // borderRadius: "50px",
                    // fontSize: "36px",
                    // padding: "5px",
                    marginTop:4,
                    marginRight:10
                  }}
                />
                <span style={{
                  // padding: 5,
                  position: 'fixed',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  //  width: '55vmin'
                }}>
                {/*}        {jsonlang.remove.toUpperCase() + " " + this.props.appstate.Catalog.resourceInfo.name}  */}
                ADVANCED SEMANTIC SEARCH
              </span>
            </DialogTitle>
            <DialogContent dividers style={{
              padding:"0px 24px",
            }}>
            <Box sx={{ width: '100%' }}>
              {/* <Box>
                <Tabs
                indicatorColor="primary"
                variant="fullWidth"
                value={this.state.tabvalue}
                onChange={this.handleChangeTab}
                aria-label="kind of research"
                >
                <Tab label="MATCHING"/>
                <Tab label="COUNTING" />
                <Tab label="AVERAGING"/>
              </Tabs>
            </Box>
            <TabPanel value={this.state.tabvalue} index={0}> */}
            <ResearchMatch data={this.restCall}/>
            {/* </TabPanel>
              <TabPanel value={this.state.tabvalue} index={1}>
              <ResearchCount data={this.restCall}/>
            </TabPanel>
            <TabPanel value={this.state.tabvalue} index={2}>
            <ResearchAverage data={this.restCall}/>
          </TabPanel> */}
        </Box>
      </DialogContent>




      {/* <DialogContent dividers>
        <Grid
        container
        direction="row"
        justifyContent="center"
        >

        <Grid item xs={12}>
        <FormControl component="fieldset">
        <Typography variant="body1" color="primary">
        KIND OF RESEARCH
      </Typography>
      <RadioGroup row aria-label="AVERAGE NUMBER" name="avenum" value="{value}" onChange="{handleChange}">
      <FormControlLabel value="mat" control={<Radio />} label="MATCHING" />
      <FormControlLabel value="cou" control={<Radio />} label="COUNTING" />
      <FormControlLabel value="ave" control={<Radio />} label="AVERAGING" />
    </RadioGroup>
  </FormControl>
</Grid>

</Grid>

<Divider
light={true}
// style={{width:"50%", marginLeft:"20%"}}
/>

<Grid
container
direction="row"
justifyContent="center"
alignItems="center"
style={{marginTop:15}}
>
<Grid item xs={12}>
<FormControl component="fieldset">
<Typography variant="body1" color="primary">
AVERAGE NUMBER
</Typography>
<RadioGroup row aria-label="AVERAGE NUMBER" name="ave" value="{value}" onChange="{handleChange}">
<FormControlLabel value="aud" control={<Radio />} label="AUDIO" />
<FormControlLabel value="pic" control={<Radio />} label="PICTURE" />
<FormControlLabel value="vid" control={<Radio />} label="VIDEO" />
<FormControlLabel value="doc" control={<Radio />} label="DOCUMENT" />
</RadioGroup>
</FormControl>
</Grid>
</Grid>

<Divider light={true}/>

<Grid
container
direction="row"
style={{marginTop:15}}
>
<FormControl component="fieldset">

<Typography variant="body1" color="primary">
TARGET
</Typography>
<RadioGroup row aria-label="TARGET" name="tar" value="{value}" onChange="{handleChange}">
<Grid item xs={12}>
<FormControlLabel value="ato" control={<Radio />} label="ATOMIC" />
<FormControlLabel value="com" control={<Radio />} label="COMPLEX" />
<FormControlLabel value="cra" control={<Radio />} label="CRAWLED" />
</Grid>
<Grid item xs={1}></Grid>
<Grid item xs={11}>
<Typography variant="caption" display="block" color="primary">
subtype
</Typography>
<FormControlLabel value="sto" control={<Radio />} label="STORYMAP" />
<FormControlLabel value="web" control={<Radio />} label="WEBDOC" />
<FormControlLabel value="ins" control={<Radio />} label="INSIGHTS" />
<FormControlLabel value="all" control={<Radio />} label="ALL" />
</Grid>
</RadioGroup>
</FormControl>
</Grid>

<Divider light={true}/>

<Grid
container
direction="row"
justifyContent="center"
alignItems="center"
style={{marginTop:25}}
>
</Grid>
</DialogContent> */}
<DialogActions>
  <Button
    variant="contained"
    size="small"
    onClick={this.onclose}
    color="primary"
    startIcon={<ClearIcon />}>
    <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
    <Typography variant="button">{jsonlang.instcancel.toUpperCase()}</Typography>
  </Tooltip>
</Button>
<Button
  variant="contained"
  size="small"
  onClick={this.search}
  color="primary"
  disabled={this.getDisabledSearch()}
  startIcon={<DoneIcon />}>
  <Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
  <Typography variant="button">{jsonlang.search.toUpperCase()}</Typography>
</Tooltip>
</Button>
</DialogActions>
</Dialog>
}
</div>
)
}
}
export default connect(mapStateToProps)(DialogAddSemanticSearch);
